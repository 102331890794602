import React, { useRef } from 'react';
import { Input, message } from 'antd';
import { SnippetsOutlined } from '@ant-design/icons';

const InputCopy = (props) => {
  // Step 1: Create a Ref
  const inputRef = useRef(null);

  // Step 2: Copy Function
  // const handleCopyClick = () => {
  //   // Check if the input field is available
  //   if (inputRef.current) {
  //     // Select the text in the input field
  //     inputRef.current.select();
  //     document.execCommand('copy');

  //     // Display a success message
  //     message.success('Text copied to clipboard');
  //   }
  // };

  const handleCopyClick = async () => {
    if (inputRef.current) {
      inputRef.current.select();

      try {
        await navigator.clipboard.writeText(props.value);
        message.success(`${props.value} is copies to clipboard`);
      } catch (err) {
        console.error('Failed to copy text to clipboard', err);
        message.error('Failed to copy to clipboard');
      }
    }
  };

  // Step 3: Render the Component
  return (
      <Input 
        onClick={handleCopyClick}
        ref={inputRef} 
        {...props} 
        suffix={<SnippetsOutlined onClick={handleCopyClick} style={{color: 'blue', fontSize: 22, marginLeft: 20}} title='click to copy' />} 
      />
  );
};

export default InputCopy;