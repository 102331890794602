import { Button, Input, Select, notification, Row, Col, Modal, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../assets/utils/useAuth";
import lib, { myRequestObj } from "../../assets/utils/lib";
import { facilityType, packages, states } from "../../assets/utils/config";
import { CheckOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";

import InputCopy from "../compoinents/copyInput.js";
import { renderButton } from "./renderButton.js";
import { same } from "../../assets/utils/Helpers.js";

const { TextArea, Search } = Input;
const { Option } = Select;

export const FacilityData = ({ data, display, action, command, key, source = 1, viewOnly = false }) => {
  const { user } = useAuth();
  const [n_data, setNdata] = useState(data);
  const [edit, setEdit] = useState(false);
  const [update, setUpdate] = useState(false);

  const [showSetFacilityPassword, setShowSetFacilityPassword] = useState(false);

  const { confirm } = Modal;

  useEffect(() => {
    setUpdate(!same(data, n_data));
  }, [n_data]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (display) {
  //     handleFocusOut();
  //   }
  // }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateDataFacility = async (e, thisData = {}) => {
    const targ = e;
    const actionType = Number(e);
    const loadingMap = {
      1: 'update',
      2: 'activate',
      3: 'disable',
      4: 'flag',
      5: 'revoke',
    };

    setEdit(false);
    setLoadingFlags(loadingMap[actionType], true)

    lib
      .updateProvider({ ...n_data, ...thisData })
      .then((res) => res?.data)
      .then((data) => {
        if (!Number(data?.status)) {
          if (Number(targ) === 4) {
            return;
          }

          notification.success({
            message: data?.message,
            placement: "topLeft",
          });

        } else {
          notification.error({
            message: data?.message,
            placement: "topLeft",
          });
        }
      })
      .catch((err) => {
        setEdit(true);
      })
      .finally(() => {
        setLoadingFlags(loadingMap[actionType], false)
        setUpdate(false);
        action();
      });
  };

  const [loadingStates, setLoadingStates] = useState({
    loading: false,
    update: false,
    activate: false,
    disable: false,
  });
  

  const setLoadingFlags = (key, value) => {
    setLoadingStates(d => ({ ...d, [key]: value }))
  }

  const isLoading = (key) => Object.values(loadingStates).some(Boolean);

  const canEdit = () => [1, 2, 4, 5, 6].includes(Number(user.role["staffRoleId"]))

  const handleCancel = () => {
    same(data, n_data)? action() : command()
  }

  return (
    <>
      <Modal
        title={
          <Row justify={'start'} align={'middle'}>
            <Col>{n_data?.facility_name}</Col>
            <Divider type="vertical" />
            <Col>
              {
                renderButton({
                  type: 'link',
                  label: data?.facility_status ? "Active" : "Inactive",
                  danger: !Number(data?.facility_status),
                })
              }
            </Col>
            {
              canEdit() ? <>
                <Divider type="vertical" style={{ borderColor: 'black' }} />
                <Col style={{ textAlign: "right", alignContent: "flex-end" }}>
                  {renderButton({
                    label: edit ? <>Close Edit</> : <>Edit</>,
                    isDisabled: isLoading(),
                    onClick: () => setEdit(!edit),
                    icon: edit ? <CheckOutlined /> : <EditOutlined />
                  })
                  }
                </Col>
              </> : null
            }
            {user && [1].includes(Number(user.role["staffRoleId"])) ? <>
              <Divider type="vertical" style={{ borderColor: 'black' }} />
              <Col>
                {renderButton({
                  label: 'Set Password',
                  isDisabled: isLoading(),
                  onClick: () => setShowSetFacilityPassword(true)
                })}
              </Col>
            </> : null}
          </Row>
        }
        centered
        open={display}
        onOk={action}
        onCancel={action}
        footer={
          <>
            {user &&
              [1, 2].includes(Number(user.role["staffRoleId"])) ? (
              <>
                {
                  update && renderButton({
                    isDisabled: isLoading(),
                    label: 'Update',
                    loadingState: loadingStates?.update,
                    onClick: () => updateDataFacility(1)
                  })
                }
                {data?.facility_status ? (
                  renderButton({
                    type: 'dashed',
                    label: 'Disable',
                    danger: true,
                    loadingState: loadingStates?.disable,
                    isDisabled: isLoading(),
                    onClick: () => updateDataFacility(3, { facility_status: 0 })
                  })
                ) : (
                  renderButton({
                    type: 'dashed',
                    label: 'Activate',
                    loadingState: loadingStates?.activate,
                    isDisabled: isLoading(),
                    onClick: () => updateDataFacility(2, { facility_status: 1 })
                  })
                )}
              </>
            ) : null}
            {
              renderButton({
                type: 'dashed',
                label: 'Close',
                danger: true,
                isDisabled: isLoading(),
                onClick: handleCancel
              })
            }
          </>
        }
        width={800}
        destroyOnClose
      >
        <Row style={{ margin: '20px 0px' }}>
          <Col span={24}>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                <Row>
                  <Col span={8}>Facility Name:</Col>
                  <Col span={16}>
                    <TextArea
                      disabled={!edit}
                      value={n_data?.facility_name}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_name: e.target.value,
                        }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Facility Type: </Col>
                  <Col span={16}>
                    <Select
                      disabled={!edit}
                      value={n_data?.facility_type}
                      onChange={(e) =>
                        setNdata((d) => ({ ...d, facility_type: e }))
                      }
                      options={facilityType}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Facility Plans:</Col>
                  <Col span={16}>
                    <Select
                      disabled={!edit}
                      value={n_data?.facility_plan_type}
                      onChange={(e) =>
                        setNdata((d) => ({ ...d, facility_plan_type: e }))
                      }
                      options={[
                        ...packages,
                        { label: "All Plans", value: "all plans" },
                      ]}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Facility State:</Col>
                  <Col span={16}>
                    <Select
                      disabled={!edit}
                      value={n_data?.facility_state}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_state: e.target.value,
                        }))
                      }
                      options={states}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Facility City:</Col>
                  <Col span={16}>
                    <Input
                      disabled={!edit}
                      value={n_data?.facility_city}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_city: e.target.value,
                        }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Facility Phone:</Col>
                  <Col span={16}>
                    <Input
                      disabled={!edit}
                      value={n_data?.facility_phone}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_phone: e.target.value,
                        }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Facility Email:</Col>
                  <Col span={16}>
                    <InputCopy
                      disabled={!edit}
                      value={n_data?.facility_email}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_email: e.target.value,
                        }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Facility Address:</Col>
                  <Col span={16}>
                    <TextArea
                      disabled={!edit}
                      value={n_data?.facility_address}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_address: e.target.value,
                        }))
                      }
                      rows={3}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Constact Person:</Col>
                  <Col span={16}>
                    <Input
                      disabled={!edit}
                      value={n_data?.facility_designation}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_designation: e.target.value,
                        }))
                      }
                      rows={3}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Regitration Onboarded:</Col>
                  <Col span={16}>
                    <Input
                      disabled
                      value={n_data?.facility_date}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_date: e.target.value,
                        }))
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>Onboarded By:</Col>
                  <Col span={16}>
                    <Input
                      disabled
                      value={n_data?.facility_staff_id}
                      onChange={(e) =>
                        setNdata((d) => ({
                          ...d,
                          facility_staff_id: e.target.value,
                        }))
                      }
                    />
                  </Col>
                </Row>
              </Col>
              {/* <Col span={24}>
                <Row justify="end" gutter={[16, 16]}>
                  <Col
                    style={{ textAlign: "right", alignContent: "flex-end" }}
                  > */}
              {/* {user &&
                       ? (
                      <>
                        {edit ? (
                          <CheckOutlined
                            onClick={() => setEdit(false)}
                            style={{ fontSize: 30, color: "blue" }}
                            title="Done"
                          />
                        ) : (
                          <EditOutlined
                            onClick={() => setEdit(true)}
                            style={{ fontSize: 30, color: "blue" }}
                          />
                        )}
                      </>
                    ) : null} */}
              {/* </Col>
                </Row>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </Modal>

      <FacilityPassword open={showSetFacilityPassword} onCancel={() => setShowSetFacilityPassword(false)} data={data} onDone={command} />
    </>
  );
};

const FacilityPassword = props => {
  const [password, setPassword] = useState()

  const handleSavePassword = () => {
    const payload = {
      id: props.data.facility_id,
      password
    }

    myRequestObj('setFacilityPassword').post(payload)
      .then(resp => {
        const data = resp?.data;
        if (!data?.error) {
          notification.success({
            message: data?.message
          })
          props.onDone()
          props.onCancel()
        } else {
          notification.success({
            message: data?.msg || data?.message
          })
        }
      })
  }

  return <Modal
    title={'Set Password'}
    cancelText={'Close'}
    okText={'Save'}
    onCancel={props.onCancel}
    onOk={handleSavePassword}
    open={props.open}
    width={500}
    destroyOnClose
  >
    <Row>
      <Col span={24}>Password</Col>
      <Col span={24}>
        <Input.Password value={password} onChange={e => setPassword(e.target.value)} width={'100%'} />
      </Col>
    </Row>
  </Modal>
}