import { Input, Select } from 'antd';
import { Fragment } from 'react';

const { TextArea } = Input;
const { Option } = Select;

export const renderField = ({label, value, onChange, inputType = 'input', options = [], isDisabled = true, viewOnly = false, placeholder = '', suffix = <></>}) => {
  if (viewOnly) {
    return <div style={{ display: 'flex', gap: '10px' }}>
      {label ? <p style={{fontWeight: 'bold'}}>{label}:</p> : null}
      <p>{!isNaN(value)? value : value?.toUpperCase()}</p>
    </div>; // Display value as plain text in view-only mode
  }

  switch (inputType) {
    case 'select':
      return (<div style={{margin: 0, padding: 0, width: '100%'}}>
        {label ? <label>{label}: </label> : null}
        <Select value={value} onChange={onChange} style={{ width: '100%' }} title={label} disabled={isDisabled} suffixIcon={suffix}>
          {options.map((opt) => (
            <Option key={opt.value} value={opt.value}>
              {opt.label}
            </Option>
          ))}
        </Select>
      </div>
      );
    case 'textarea':
      return (<div style={{margin: 0, padding: 0, width: '100%'}}>
        {label ? <label>{label}: </label> : null}
        <TextArea
          value={value}
          onChange={onChange}
          title={label}
          disabled={isDisabled}
          rows={4} // You can customize rows as per your need
        />
        </div>
      );
    case 'password':
      return (<div style={{margin: 0, padding: 0, width: '100%'}}>
        {label ? <label>{label}: </label> : null}
        <Input.Password value={value} onChange={onChange} title={label} disabled={isDisabled} suffix={suffix}/>
        </div>);
    case 'number':
      return (<div style={{margin: 0, padding: 0, width: '100%'}}>
        {label ? <label>{label}: </label> : null}
        <Input type="number" value={value} onChange={onChange} title={label} disabled={isDisabled} suffix={suffix}/>
      </div>);
    case 'date':
      return (<div style={{margin: 0, padding: 0, width: '100%'}}>
        {label ? <label>{label}: </label> : null}
        <Input type="date" value={value} onChange={onChange} title={label} disabled={isDisabled} suffix={suffix}/>
      </div>);
    default:
      return (<div style={{margin: 0, padding: 0, width: '100%'}}>
        {label ? <label>{label}: </label> : null}
        <Input value={value} onChange={onChange} title={label} disabled={isDisabled} suffix={suffix}/>
      </div>);
  }
};

export const renderFieldAlt = (label, value, onChange, inputType = 'input', options = [], isDisabled = true, viewOnly = false, placeholder = '') => {
  if (viewOnly) {
    return <div style={{ display: 'flex', gap: '10px' }}>
      <p style={{fontWeight: 'bold'}}>{label}:</p>
      <p>{!isNaN(value)? value : value?.toUpperCase()}</p>
    </div>; // Display value as plain text in view-only mode
  }

  switch (inputType) {
    case 'select':
      return (<>
        <label>{label}: </label>
        <Select value={value} onChange={onChange} style={{ width: '100%' }} title={label} disabled={isDisabled}>
          {options.map((opt) => (
            <Option key={opt.value} value={opt.value}>
              {opt.label}
            </Option>
          ))}
        </Select>
      </>
      );
    case 'textarea':
      return (<>
        <label>{label}: </label>
        <TextArea
          value={value}
          onChange={onChange}
          title={label}
          disabled={isDisabled}
          rows={4} // You can customize rows as per your need
        />
        </>
      );
    case 'password':
      return (<>
        <label>{label}: </label>
        <Input.Password value={value} onChange={onChange} title={label} disabled={isDisabled} />
        </>);
    case 'number':
      return (<>
        <label>{label}: </label>
        <Input type="number" value={value} onChange={onChange} title={label} disabled={isDisabled} />
      </>);
    case 'date':
      return (<>
        <label>{label}: </label>
        <Input type="date" value={value} onChange={onChange} title={label} disabled={isDisabled} />
      </>);
    default:
      return (<>
        <label>{label}: </label>
        <Input value={value} onChange={onChange} title={label} disabled={isDisabled} />
      </>);
  }
};