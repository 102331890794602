import React, { useState } from 'react';
import { Input, Select, Space, Tooltip, Spin, Row, Col } from 'antd';
import debounce from 'lodash.debounce';

const { Search } = Input;
const { Option, OptGroup } = Select;

const SearchWithAdvancedFeatures = ({
  dropdownOptions = [],       // Options for the dropdown
  onDropdownChange,           // Callback for dropdown selection
  onSearch,                   // Callback for search action
  onSearchChange,             // Callback for search input change
  showDropdown = true,        // Toggle dropdown visibility
  allowClear = true,          // Allow clearing of inputs
  defaultDropdownValue = null, // Default value for dropdown
  defaultSearchValue = '',     // Default value for search
  customButtonLabel = 'Search', // Label for the search button
  theme = 'light',             // Theme: 'light' or 'dark'
  size="large",
  customStyles = {},
  loading = false,
}) => {
  const [dropdownValue, setDropdownValue] = useState(defaultDropdownValue);
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
//   const [loading, setLoading] = useState(false);

  // Dynamic styles for theme
  const styles = theme === 'dark' ? { backgroundColor: '#333', color: '#fff' } : {};

  // Debounced search input change handler
  const handleDebouncedSearchChange = debounce((value) => {
    onSearchChange && onSearchChange(value);
  }, 300);

  // Handle dropdown change
  const handleDropdownChange = (value) => {
    setDropdownValue(value);
    onDropdownChange && onDropdownChange(value);
  };

  // Handle search action
  const handleSearch = async (value) => {
    onSearch && onSearch({ keyword: value, dropdown: dropdownValue });
  };

  return (
    <Row style={{ width: '100%', ...customStyles.container }}>
      {/* Dropdown */}
      {showDropdown && dropdownOptions.length > 0 && (
        <Col xs={24} sm={12} md={8} lg={6}>
          <Select
            placeholder="Select an option"
            style={customStyles.select}
            onChange={handleDropdownChange}
            allowClear={allowClear}
            defaultValue={defaultDropdownValue}
            size={size}
            disabled={loading}
          >
            {dropdownOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
      )}

      {/* Search Input */}
      <Col
        xs={24}
        sm={showDropdown && dropdownOptions.length > 0 ? 12 : 24}
        md={showDropdown && dropdownOptions.length > 0 ? 16 : 24}
        lg={showDropdown && dropdownOptions.length > 0 ? 18 : 24}
      >
        <Search
          style={{
            width: '100%',
            ...customStyles.search,
          }}
          placeholder="Enter your filter words ..."
          allowClear={allowClear}
          onSearch={handleSearch}
          onChange={(e) => {
            setSearchValue(e.target.value);
            handleDebouncedSearchChange(e.target.value);
          }}
          enterButton={loading ? <Spin /> : customButtonLabel}
          size={size}
          defaultValue={defaultSearchValue}
          disabled={loading}
        />
      </Col>
    </Row>
  );
};

export default SearchWithAdvancedFeatures;
