import React, { useEffect, useState } from 'react';
import { Upload, message, Modal, notification } from 'antd';
import lib from '../../assets/utils/lib';
import { ThreeCircles } from 'react-loader-spinner';

const UploadImage = (props) => {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isUploadVisible, setIsUploadVisible] = useState(props?.show);

  useEffect(() => {
    if(isUploadVisible){    }
  }, [isUploadVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = () => {
    typeof props?.onClose === 'function' && props?.onClose?.()
    setIsUploadVisible(false);
  };

  const onChange = async ({ fileList: newFileList }) => {
    if(loading) return;

    const isLt2M = newFileList[0]?.size / 1024 / 1024 < 6;

    if (!isLt2M) {
      console.log(isLt2M);
      message.error('Image must be less than 6MB!');
      return
    } 

    setFileList(newFileList);

    let form = new FormData();
    form.append('insurance_no', props.data.insurance_no)
    form.append('file', newFileList[0].originFileObj)

    setLoading(true);
    
    let reqData = await lib.sendImage(form)

    if (reqData.status === 'error') {
        notification.error({
            message: reqData.msg, placement: 'topLeft'
        })
    }

    if (reqData?.error === 1) {
        notification.error({
            message: "Image upload Failed", placement: 'topLeft'
        })
    }

    if (reqData?.error === 0) {
        notification.success({
            message: reqData.message, placement: 'topLeft'
        })
        setIsUploadVisible(false);
        setTimeout(()=> window.location.reload(), 500)
    }

    setLoading(false);
  };

  return (
    <>
      <Modal title="Upload Identifications" open={isUploadVisible} onOk={handleOk} onCancel={handleOk} destroyOnClose>
          <div className="profile-password">
              <div className='form-group' style={{marigin: "0 auto", width: 'fit-content'}}>
                  <h3>Upload Image</h3>
                  <Upload
                      action={'#'}
                      listType="picture-card"
                      onChange={onChange}
                      style={{width: 200}}
                  >
                      {
                          loading?
                              <ThreeCircles
                                  height="100"
                                  width="100"
                                  color="#4fa94d"
                                  wrapperStyle={{}}
                                  wrapperClass=""
                                  visible={true}
                                  ariaLabel="three-circles-rotating"
                                  outerCircleColor=""
                                  innerCircleColor=""
                                  middleCircleColor=""
                              />:
                              <>{fileList.length < 1 && '+ Upload'}</>
                      }
                  </Upload>
                  {/* </ImgCrop> */}
              </div>
          </div>
      </Modal>
    </>
  )
}
export default UploadImage;