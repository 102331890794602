import {
  notification,
  Row,
  Col,
  Empty,
  Segmented,
  Badge,
  Switch,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../assets/utils/useAuth";
import useLocalStorage from "../../assets/utils/useLocal";
import { useDispatch, useSelector } from "react-redux";
import "./clients.css";
import config from "../../assets/utils/config";
import {
  LoadingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Helpers from "../../assets/utils/Helpers";
import { selectclient } from "../../stores/verify";
import Ribbon from "antd/es/badge/Ribbon";
import { EnroleeBiodata } from "../compoinents/enroleeData";
import { PolicyHolder } from "../compoinents/userData";

const ClientVerufyHook = (props) => {
  const { set, user } = useAuth();
  const navigate = useNavigate();

  const dispatcher = useDispatch(selectclient);

  const [loading, ] = useState(false);

  const activeSeg = useRef(0);
  const activeProfileType = useRef(true);

  const [activeSegment, setActiveSegment] = useLocalStorage(
    config.key.activeSeg
  );
  const [activeProfileTypeLocal, setActiveProfileTypeLocal] = useLocalStorage(
    config.key.activePrifileType
  );

  const [activeSegState, setActiveSegState] = useState(
    activeSegment ? activeSegment : 0
  );
  const [activeProfileTypeState, setActiveProfileTypeState] = useState(
    activeProfileTypeLocal ? activeProfileTypeLocal : true
  );

  const [, setCurrentData] = useState([]);

  const clientDateNewPolicy = useSelector(
    (state) => state.ClientStore.client?.clients?.new
  );
  const clientDateUpdatedPolicy = useSelector(
    (state) => state.ClientStore.client?.clients?.updated
  );
  const clientDateNewEnrollees = useSelector(
    (state) => state.ClientStore.client?.enrollees?.new
  );
  const clientDateUpdateEnrollees = useSelector(
    (state) => state.ClientStore.client?.enrollees?.updated
  );

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      if (![1, 2].includes(Number(user.role["staffRoleId"]))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: "Invalid User",
          description: "Please login with the right permission",
          placement: "topLeft",
        });
        navigate("/login");
      }
    }
  });

  

  useEffect(() => {
    if (activeProfileTypeState) {
      if (!activeSegState) {
        setCurrentData(clientDateNewPolicy);
      } else {
        setCurrentData(clientDateUpdatedPolicy);
      }
    } else {
      if (!activeSegState) {
        setCurrentData(clientDateNewEnrollees);
      } else {
        setCurrentData(clientDateUpdateEnrollees);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeProfileTypeState) {
      if (!activeSegState) {
        setCurrentData(clientDateNewPolicy);
      } else {
        setCurrentData(clientDateUpdatedPolicy);
      }
    } else {
      if (!activeSegState) {
        setCurrentData(clientDateNewEnrollees);
      } else {
        setCurrentData(clientDateUpdateEnrollees);
      }
    }
  }, [activeSegState, activeProfileTypeState]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuClick = (e) => {
    activeSeg.current = Number(e);
    setActiveSegState(activeSeg.current);
    setActiveSegment(activeSeg.current);
  };

  const switchProfile = (e) => {
    activeProfileType.current = e;
    setActiveProfileTypeState(activeProfileType.current);
    setActiveProfileTypeLocal(activeProfileType.current);
  };

  const style = {
    padding: "8px 0",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "1.2em",
    fontWeight: "bold",
  };

  return (
    <>
      {user ? (
        <>
          <Segmented
            block
            onChange={handleMenuClick}
            defaultChecked={activeSegState}
            options={[
              {
                label: (
                  <div style={{ ...style, cursor: "pointer" }}>
                    <Badge
                      count={
                        activeProfileType.current
                          ? clientDateNewPolicy?.length
                          : clientDateNewEnrollees?.length
                      }
                    >
                      <span
                        style={{
                          padding: 20,
                          fontSize: "1.2em",
                          color: "#1a1af1",
                        }}
                      >
                        New Entries
                      </span>
                    </Badge>
                  </div>
                ),
                value: 0,
              },
              {
                label: (
                  <div style={{ ...style, cursor: "pointer" }}>
                    <Badge
                      count={
                        activeProfileType.current
                          ? clientDateUpdatedPolicy?.length
                          : clientDateUpdateEnrollees?.length
                      }
                    >
                      <span
                        style={{
                          padding: 20,
                          fontSize: "1.2em",
                          color: "#1a1af1",
                        }}
                      >
                        Renewal / Update
                      </span>
                    </Badge>
                  </div>
                ),
                value: 1,
              },
            ]}
          />
          <Switch
            checkedChildren={
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "18px",
                  width: "100%",
                }}
              >
                <UserOutlined /> <span>Policy Accounts</span>
              </p>
            }
            unCheckedChildren={
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "18px",
                  width: "100%",
                  marginTop: "-21%",
                }}
              >
                <TeamOutlined />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>Enrolees' Bio-Data</span>
              </p>
            }
            defaultChecked={activeProfileTypeState}
            style={{ background: "#1677ff" }}
            onChange={switchProfile}
          />
          <Row
            gutter={[16, 24]}
            style={{ marginTop: 10, padding: "0 auto !important" }}
            justify="center"
          >
            {!loading ? (
              <>
                {!loading ? (
                  <Col span={24}>
                    {activeProfileType.current ? (
                      <h5 style={{ textAlign: "center" }}>
                        Policy Accounts -{" "}
                        {!activeSegState
                          ? clientDateNewPolicy?.length
                          : clientDateUpdatedPolicy?.length}
                      </h5>
                    ) : (
                      <h5 style={{ textAlign: "center", marginTop: "20px" }}>
                        Enroless Biodata -{" "}
                        {!activeSegState
                          ? clientDateNewEnrollees?.length
                          : clientDateUpdateEnrollees?.length}
                      </h5>
                    )}
                    {activeProfileType.current
                      ? !activeSegState
                        ? clientDateNewPolicy?.map((pol) => (
                            <Ribbon
                              placement="start"
                              text={pol?.category}
                              color={"#520339"}
                            >
                              <PolicyHolder data={pol} key={pol?.id}/>
                            </Ribbon>
                          ))
                        : clientDateUpdatedPolicy?.map((pol) => (
                            <Ribbon
                              placement="start"
                              text={pol?.category}
                              color={"#520339"}
                            >
                              <PolicyHolder data={pol} key={pol?.id}/>
                            </Ribbon>
                          ))
                      : !activeSegState
                      ? clientDateNewEnrollees?.map((bio) => (
                          <EnroleeBiodata
                            data={bio}
                            key={bio?.id}
                          />
                        ))
                      : clientDateUpdateEnrollees?.map((bio) => (
                          <EnroleeBiodata
                            data={bio}
                            key={bio?.id}
                          />
                        ))}
                  </Col>
                ) : (
                  <Empty
                    description={false}
                    image={
                      <LoadingOutlined
                        style={{ fontSize: 200, margin: "100px auto" }}
                      />
                    }
                  />
                )}
              </>
            ) : (
              <LoadingOutlined
                style={{ fontSize: 200, margin: "100px auto" }}
              />
            )}
          </Row>
        </>
      ) : null}
    </>
  );
};


export default ClientVerufyHook;
