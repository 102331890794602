import { CheckOutlined, EditOutlined, LoadingOutlined, UsergroupAddOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Modal, Row, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { packages, states, policy_type } from "../../assets/utils/config";
import lib, { isEqual } from "../../assets/utils/lib";
import { useAuth } from "../../assets/utils/useAuth";
import { RenewPolicy } from "./policyRenew";
import { renderButton } from "./renderButton";
import { DependantEdit } from "./enroleeData";
import { renderField } from "./renderField";
import { same } from "../../assets/utils/Helpers";

const { TextArea } = Input;
const { confirm } = Modal;

export const PolicyHolder = ({ data, showAllActions = false }) => {
  const [show, setShow] = useState(false);

  const launch = (e) => {
    setShow(!show);
  };

  return (
    <>
      <Row
        style={{
          padding: "10px",
          borderBottom: "1px solid black",
          borderTop: "1px solid black",
          margin: "10px 0",
          cursor: "pointer",
        }}
        onClick={() => launch(data, "clients")}
      >
        <Col span={8}>
          <Row>
            <Col span={8}>Name: </Col>
            <Col span={16}>{data?.name}</Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row>
            <Col span={10}>Insurance Package: </Col>
            <Col span={14}>{data?.insurance_package}</Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col span={12}>Premium Type: </Col>
            <Col span={12}>{data?.premium_type?.toUpperCase()}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8}>Enrolee Size: </Col>
            <Col span={16}>{data?.enrolee_size}</Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row>
            <Col span={10}>Email: </Col>
            <Col span={14}>{data?.email}</Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col span={12}>Phone: </Col>
            <Col span={12}>{data?.phone}</Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col span={8}>Policy Number: </Col>
            <Col span={16}>{data?.policy_no}</Col>
          </Row>
        </Col>
        <Col span={10}>
          <Row>
            <Col span={10}>Reg. Date: </Col>
            <Col span={14}>{data?.date}</Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col span={12}>State / City: </Col>
            <Col span={12}>
              {data?.state?.toUpperCase()} / {data?.city?.toUpperCase()}
            </Col>
          </Row>
        </Col>
      </Row>

      <PolicyHolderModal
        data={data}
        display={show}
        action={() => setShow(false)}
        onclose={launch}
        showAllActions={showAllActions}
      />
    </>
  );
};

export const PolicyHolderModal = ({ data, display, action, onclose = () => null, showAllActions = false, viewOnly = false }) => {
  const { user } = useAuth();
  const [n_data, setNdata] = useState(data);
  const [edit, setEdit] = useState(false);
  const [update, setUpdate] = useState(false);

  const [validatingSocial, setValidatingSocial] = useState(false);
  const [socialDetail, setSocialDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setUpdate(!same(data, n_data));
  }, [n_data]); //eslint-disable-line react-hooks/exhaustive-deps

  const [loadingStates, setLoadingStates] = useState({
    loading: false,
    update: false,
    activate: false,
    disable: false,
    flag: false,
    revoke: false,
    validateSocial: false,
    category: false,
    edit: false
  });

  const setLoadingFlags = (key, value) => {
    setLoadingStates(d => ({ ...d, [key]: value }))
  }

  const isLoading = (key) => Object.values(loadingStates).some(Boolean);

  const expiryUpdateHandler = () => {
  }

  useEffect(() => {
    if (display && n_data?.category !== 'self pay') {
      verifyCode();
    }
  }, [display]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const vn = Object.entries(n_data).filter((v, i) => v[1] !== data[v[0]]);
    if (vn.length) setUpdate(true);
  }, [n_data]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateData = async (e, data) => {
    const actionType = Number(e);
    const loadingMap = {
      1: 'update',
      2: 'activate',
      3: 'disable',
      4: 'flag',
      5: 'revoke',
    };

    const key = loadingMap[actionType];
    setLoadingFlags(key, true);
    setEdit(false);

    lib
      .updateUserData({ ...n_data, ...data })
      .then((res) => res.data)
      .then((data) => {
        if (!data?.error) {
          notification.success({
            message: data?.message,
            placement: "topLeft",
          });
        } else {
          notification.error({
            message: data?.message,
            placement: "topLeft",
          });
        }
      })
      .catch((err) => {
        setEdit(true);
      })
      .finally(() => {
        setLoadingFlags(key, false);
        setLoadingFlags('update', false);
        setUpdate(false);
        action();
      });
  };

  const handleFocusOut = async (e) => { // eslint-disable-line
    setValidatingSocial(true);
    let obj = {
      social_no: n_data?.social_no,
      mode: "corporate",
    };

    let reqData = await lib.validatSocial(obj);
    if (reqData.status === "error") {
      notification.error({ message: reqData.msg, placement: "topLeft" });
    }

    if (reqData?.error === 0) {
      setSocialDetail(reqData?.data);
    }
    setValidatingSocial(false);
  };

  const configFlag = {
    title: (
      <>Flag - {data?.policy_no}</>
    ),
    onOk: () => {
      updateData(4);
    },
    okText: "Raise Flag",
    width: 700,
    centered: true,
    maskClosable: true,
    content: (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>Flag Memo: </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) =>
                setNdata((d) => ({ ...d, flag_reason: e.target.value }))
              }
              style={{ width: "100%" }}
              rows={4}
            />
          </Col>
        </Row>
      </>
    ),
  };

  const configUnFlag = {
    title: (
      <>Unflag - {data?.policy_no}</>
    ),
    onOk() {
      updateData(4);
    },
    centered: true,
    maskClosable: true,
    okText: "Remove Flag",
    content: (
      <>
        <Row>
          <Col span={24}>Flag Memo: </Col>
          <Col span={24}>
            <TextArea
              onChange={(e) => setNdata((d) => ({ ...d, flag_reason: e }))}
              style={{ width: "100%" }}
              rows={4}
            />
          </Col>
        </Row>
      </>
    ),
  };

  const focalData = { category: data?.category, social_no: data?.social_no };
  const [updateCategory, setUpdateCategory] = useState(false);
  const [updateCategoryValidating, setUpdateCategoryValidating] = useState(false);
  const [categoryUpdateData, setCategoryUpdateData] = useState({ ...focalData });
  const [categoryVerified, setCategoryVerified] = useState(false);
  const [UpdateSocialDetail, setUpdateSocialDetail] = useState("");
  const [showEnrolleeAddition, setShowEnrolleeAddition] = useState(false);

  const updateUseCategory = () => {
    if (
      categoryUpdateData?.category === "self pay" &&
      categoryUpdateData?.social_no
    )
      categoryUpdateData.social_no = "";

    setLoading(true)

    lib
      .updateUserData({ ...n_data, ...categoryUpdateData })
      .then((res) => res.data)
      .then((data) => {
        if (!data?.error) {
          notification.success({
            message: data?.message,
            placement: "topLeft",
          });
          setUpdateCategory()
        } else {
          notification.error({
            message: data?.message,
            placement: "topLeft",
          });
        }
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false)
      });
  };

  const verifyCode = async (e) => {
    setUpdateCategoryValidating(true);
    setCategoryVerified(false);
    let obj = {
      social_no: categoryUpdateData?.social_no || data?.social_no,
      mode: categoryUpdateData?.category || data?.category,
    };

    let reqData = await lib.validatSocial(obj);
    if (Number(reqData?.data?.error) === 0) {
      setUpdateSocialDetail(reqData?.data?.data);
      setSocialDetail(reqData?.data?.data)
      setCategoryVerified(true);
    } else {
      notification.error({
        message: reqData?.data?.message,
        placement: "topLeft",
      });
    }
    setValidatingSocial(false)
    setUpdateCategoryValidating(false);
  };

  const canEdit = (user = null, data = {}) => {
    return user && [1, 2].includes(Number(user?.role["staffRoleId"])) && (Number(data?.status) || (Number(data?.updated) || Number(data?.is_new)))
  }

  return (
    <>
      <Modal
        title={
          <Row gutter={5} justify={'start'} align={'middle'}>
            <Col style={{ fontWeight: 'bolder' }}>Client - {data?.policy_no}</Col>
            <Divider type="vertical" style={{ borderColor: 'black' }} />
            <Col>
              {renderButton({ type: 'link', label: <span style={{ fontWeight: 'bolder', cursor: 'default' }}>{Number(data?.status) ? "Active" : "Inactive"}</span>, danger: !Number(data?.status) })}
            </Col>
            {[1].includes(Number(user.role["staffRoleId"])) && data?.category?.toLowerCase() !== 'corporate' && Number(data?.status) ? <>
              <Divider type="vertical" style={{ borderColor: 'black' }} />
              <Col>
                <RenewPolicy data={data} action={expiryUpdateHandler} key={data?.policy_no} />
              </Col>
            </> : null}
            {
              canEdit(user, data) ? <>
                <Divider type="vertical" style={{ borderColor: 'black' }} />
                <Col style={{ textAlign: "right", alignContent: "flex-end" }}>
                  {renderButton({
                    label: edit ? <>Close Edit</> : <>Edit</>,
                    isDisabled: isLoading(),
                    onClick: () => setEdit(!edit),
                    icon: edit ? <CheckOutlined /> : <EditOutlined />
                  })
                  }
                </Col>
              </> : null
            }
            {update ? <>
              <Divider type="vertical" />
              <Col >
                {
                  renderButton({
                    label: loadingStates?.update ? <span><LoadingOutlined /> Updating</span> : <>Update</>,
                    key: 'update',
                    isDisabled: isLoading(),
                    loadingState: loadingStates?.update,
                    actionType: 3,
                    onClick: () => updateData(1),
                  })
                }
              </Col>
            </> : null}
            {Number(data?.status) ? (<>
              <Divider type="vertical" style={{ borderColor: 'black' }} />
              {renderButton({
                icon: <UsergroupAddOutlined />,
                onClick: () => setShowEnrolleeAddition(true),
                label: 'Add An Enrollee',
                isDisabled: isLoading(),
              })}</>
            ) : null}
          </Row>
        }
        centered
        open={display}
        onCancel={onclose}
        footer={
          <>
            {Number(data?.status) && canEdit(user, data) ? (
              <Button
                type="primary"
                disabled={isLoading()}
                style={{ backgroundColor: "grey", border: "1px solid red" }}
                onClick={() => {
                  updateData(3, { status: 0 });
                }}
              >
                {loadingStates?.disable ? <LoadingOutlined /> : <>Disable</>}
              </Button>
            ) : (
              canEdit(user, data) && !Number(data?.status) ? <>
                <Button
                  type="primary"
                  disabled={loadingStates?.activate}
                  onClick={() =>
                    updateData(2, { status: 1, update_required: 0, paymentStatus: 1, updated: Number(data?.updated) || 1, is_new: 0 })
                  }
                  loading={isLoading()}
                >
                  <>{loadingStates?.activate ?? <LoadingOutlined />} Activate</>
                </Button>
                {!showAllActions ?
                  <Button
                    type="primary"
                    spellCheck
                    danger
                    disabled={loadingStates?.revoke}
                    onClick={() =>
                      updateData(5, { status: 0, update_required: 1, updated: 0, is_new: 0 })
                    }
                    loading={isLoading()}
                  >
                    <>{loadingStates?.revoke ?? <LoadingOutlined />} Revoke</>
                  </Button> : null
                }
              </> : null)}
            {showAllActions ? <>
              {data?.status ?
                renderButton({
                  label: <>{loadingStates?.flag ?? <LoadingOutlined />}{data?.flagged ? <>Unflag</> : <>Flag</>}</>,
                  loadingState: loadingStates?.flag,
                  isDisabled: Object.values(loadingStates).some(Boolean),
                  danger: true,
                  ghost: true,
                  onClick: () => {
                    setNdata((d) => ({ ...d, flagged: data?.flag ? 0 : 1 }));
                    confirm(data?.flag ? configUnFlag : configFlag);
                  }
                })
                : null}
            </> : null}
            <Button
              type="dashed"
              disabled={isLoading()}
              danger
              onClick={onclose}
            >
              Close
            </Button>
          </>
        }
        width={800}
        destroyOnClose
      >
        <Row gutter={[24, 8]} style={{ margin: '20px 0' }}>
          <Col span={12}>
            <Divider orientation="left" orientationMargin={0} style={{ margin: 0 }}>Personal Information</Divider>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                {renderField({
                  viewOnly: viewOnly,
                  label: 'Name',
                  isDisabled: !edit,
                  value: n_data?.name,
                  onChange: (e) => setNdata((d) => ({ ...d, name: e.target.value }))
                })}
              </Col>
              <Col span={24}>
                {renderField({
                  viewOnly: viewOnly,
                  label: 'Phone',
                  inputType: 'input',
                  isDisabled: true,
                  value: n_data?.phone,
                  onChange: (e) => setNdata((d) => ({ ...d, phone: e.target.value }))
                })}
              </Col>
              <Col span={24}>
                {renderField({
                  viewOnly: viewOnly,
                  label: 'Policy No.',
                  inputType: 'input',
                  isDisabled: true,
                  value: n_data?.policy_no,
                  onChange: (e) => setNdata((d) => ({ ...d, policy_no: e.target.value }))
                })}
              </Col>
              <Col span={24}>
                {
                  renderField({
                    viewOnly: viewOnly,
                    label: 'Email',
                    value: n_data?.email,
                    isDisabled: true,
                  })
                }
              </Col>
              <Col span={24}>
                {
                  renderField({
                    viewOnly: viewOnly,
                    label: 'State',
                    value: n_data?.state,
                    isDisabled: !edit,
                    options: states,
                    inputType: 'select',
                    onChange: (e) => setNdata((d) => ({ ...d, state: e }))
                  })
                }
              </Col>
              <Col span={24}>
                {
                  renderField({
                    viewOnly: viewOnly,
                    isDisabled: !edit,
                    label: 'City',
                    value: n_data?.city,
                    onChange: (e) => setNdata((d) => ({ ...d, city: e.target.value }))
                  })
                }
              </Col>

            </Row>
          </Col>
          <Col span={12}>
            <Divider orientation="left" orientationMargin={0} style={{ margin: 0 }}>Premium Details</Divider>
            <Row gutter={[16, 8]}>
              <Col span={24}>
                {renderField({
                  viewOnly: viewOnly,
                  label: 'Insurance Package',
                  inputType: 'select',
                  options: packages,
                  isDisabled: !edit,
                  value: n_data?.insurance_package,
                  onChange: (e) => setNdata((d) => ({ ...d, insurance_package: e }))
                })}
              </Col>
              <Col span={24}>
                {renderField({
                  viewOnly: viewOnly,
                  label: 'Premium Type',
                  inputType: 'select',
                  options: policy_type,
                  isDisabled: !edit,
                  value: n_data?.premium_type,
                  onChange: (e) => setNdata((d) => ({ ...d, premium_type: e }))
                })}
              </Col>
              <Col span={24}>
                <Row align={'bottom'}>
                  <Col span={20}>
                    {renderField({
                      viewOnly: viewOnly,
                      label: 'Premium Category',
                      inputType: 'input',
                      isDisabled: true,
                      value: n_data?.category,
                      onChange: (e) => setNdata((d) => ({ ...d, category: e.target.value }))
                    })}
                  </Col>
                  <Col span={4}>
                    {
                      renderButton({
                        danger: true,
                        type: 'dashed',
                        label: 'Change',
                        onClick: () => setUpdateCategory(true)
                      })
                    }
                  </Col>
                </Row>
              </Col>
              {data?.social_no && data?.category?.toLowerCase() === "corporate" ? <Col span={24}>
                {
                  renderField({
                    viewOnly: viewOnly,
                    label: 'Corporate Code',
                    value: socialDetail?.org_name || n_data?.social_no,
                    isDisabled: true,
                    suffix: <>{
                      validatingSocial && !socialDetail ? (
                        <LoadingOutlined style={{ color: "blue" }} />
                      ) : !validatingSocial && socialDetail ? (
                        <CheckOutlined style={{ color: "green" }} />
                      ) : (
                        <WarningOutlined style={{ color: "gold" }} />
                      )
                    }</>
                  })
                }
              </Col> : null}
              <Col span={24}>
                {
                  renderField({
                    viewOnly: viewOnly,
                    label: 'Enrollee Size',
                    value: n_data?.enrolee_size,
                    isDisabled: !edit,
                    onChange: e => setNdata((d) => ({
                      ...d,
                      enrolee_size: e.target.value
                    }))
                  })
                }
              </Col>
              <Col span={24}>
                <Row gutter={[10, 10]}>
                  <Col span={12}>
                    {
                      renderField({
                        viewOnly: viewOnly,
                        isDisabled: true,
                        label: 'Registered On',
                        value: n_data?.date ? (new Date(n_data?.date)).toDateString() : "Invalid Date",
                      })
                    }
                  </Col>
                  <Col span={12}>
                    {
                      renderField({
                        viewOnly: viewOnly,
                        isDisabled: true,
                        label: 'Valid Till',
                        value: n_data?.expiry_date ? (new Date(n_data?.expiry_date)).toDateString() : "Invalid Date",
                      })
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      {/* Social Code Update Modal */}
      <Modal
        title="Plan Category Edit"
        open={updateCategory}
        onOk={updateUseCategory}
        onCancel={() => setUpdateCategory(false)}
        destroyOnClose
        footer={user && [1, 2].includes(Number(user?.role["staffRoleId"])) ? [
          <Button
            type="primary"
            onClick={updateUseCategory}
            // disabled={(!categoryVerified && (categoryUpdateData?.category !== 'self pay' || n_data?.category !== 'self pay')) || ((categoryUpdateData?.category === 'corporate' || n_data?.category === 'corporate') && (categoryUpdateData?.social_no === n_data.social_no || !categoryUpdateData?.social_no?.length))}
            disabled={!(!loading && categoryVerified) || isEqual(categoryUpdateData, focalData)}
          >
            Update
          </Button>,
          <Button
            type="primary"
            danger
            onClick={() => setUpdateCategory(false)}
          >
            Close
          </Button>,
        ] : null}
      >
        <Row gutter={[12, 12]}>
          <Col span={6}>Policy Category</Col>
          <Col span={18}>
            <Select
              value={categoryUpdateData?.category}
              onChange={(e) => {
                setCategoryUpdateData((d) => ({ ...d, category: e }));
                e === 'corporate' && setCategoryVerified(false)
              }}
              options={[
                { label: "Self Pay", value: "self pay" },
                { label: "Corporate", value: "corporate" },
              ]}
              style={{ width: "100%" }}
            />
          </Col>
          {(categoryUpdateData?.category === "corporate") && (
            <>
              <Col span={6}>Assocaition Code</Col>
              <Col span={14}>
                <Input
                  value={categoryUpdateData?.social_no}
                  onChange={(e) => {
                    setCategoryUpdateData((d) => ({
                      ...d,
                      social_no: e.target.value,
                    }))
                  }}
                  onKeyUp={() => setCategoryVerified(false)}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={4}>
                <Button
                  type="dashed"
                  danger
                  onClick={verifyCode}
                  style={{ width: "100%" }}
                  disabled={updateCategoryValidating}
                >
                  verify
                </Button>
              </Col>
              <Col span={6}></Col>
              <Col span={18}>{UpdateSocialDetail?.org_name}</Col>
            </>
          )}
        </Row>
      </Modal>

      {/* Dependent Onboarding */}
      <DependantEdit
        style={{ top: 10 }}
        isModalVisible={showEnrolleeAddition}
        handleCancel={() => setShowEnrolleeAddition(false)}
        data={data}
        command={() => setShowEnrolleeAddition(false)}
        key={data?.policy_no}
      />
    </>
  );
};