import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { Button, Row, Col, Empty, Modal, notification, Menu, Typography, Drawer, Space, Table, InputNumber, Spin } from 'antd';
import {  EditTwoTone, HourglassOutlined, CloseSquareFilled, CheckSquareFilled, MenuUnfoldOutlined, CheckSquareTwoTone, MessageTwoTone } from '@ant-design/icons';
import { useAuth } from '../../assets/utils/useAuth';
import './index.css';
import { fetchPaginatedData, myRequest, removeDuplicates, updateChangedObjects } from "../../assets/utils/lib";
import TextArea from "antd/es/input/TextArea";
import config from "../../assets/utils/config";

const Pricing = () => {
    const { confirm} = Modal;
    const {user} = useAuth();
    const [showFacilityFilings, setShowFacilityFilings] = useState(false);
    const [filedItemsLoading, setFiledItemsLoading] = useState(false);
    const [deleting, ] = useState(false);
    const [editing, ] = useState(false);
    const [submittingEdit, setSubmittingEdit] = useState(false);
    const [allDataPulled,  ] = useState(false);
    const [facilityFiledItems, setFacilityFiledItems] = useState([]);
    const [focusFacility, setFocusFacility] = useState({})
    const [focusFacilityData, setFocusFacilityData] = useState({})
    const [itemToEdit, setItemToEdit] = useState({})
    const [messaging, setMessaging] = useState(false);
    const [messagingItem, setMessagingItem] = useState({});

    useEffect(() => {
        if(!allDataPulled){
            fetFacilities();
        }
    }, [allDataPulled]);

    const [n_data, set_N_Data] = useState([]);

    const fetchCostable = async (facility) => {
        if(!Object.keys(facility).length){
            notification.error({message: 'No Facility Is Specified, Kindly Click on Close to pick a facility'})
            return
        }
        setFacilityFiledItems([]);
        for await (const data of (fetchPaginatedData('post', 'facilityFiledItems', facility))) {
            setFacilityFiledItems(d => ([...d, ...data]));
        }
    }

    const fetFacilities = async () => {
        const daa = []
        try{
            for await (const data of (fetchPaginatedData('post', 'loadFacilities', {}))) {
                set_N_Data(d => ([...Object.values(data), ...d]));
                daa.push(...data);
            }
            set_N_Data(daa);
            setFiledItemsLoading(false)
        }catch(e){
            notification.error({message: 'Network Error'});
        }
    }

    const menuAction = (e, _, record) => {
        switch (e.key) {
            case '1':
                setItemToEdit(record)
                record = {...record, cost_hmo: record.cost_facility, cost: record.cost_facility, pricing_status: 1};
                saveChange(record, itemToEdit);
                break;
            case '2':
                proposePrice(_, record);
                break;
            case '3':
                showMessage(_, record);
                break;
            default:
                break;
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'item_name',
            key: 'item_name',
            render: (text) => <Button type="link">{text}</Button>,
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: 'Brand',
            dataIndex: 'item_brand',
            key: 'item_brand',
        },
        {
            title: 'Strength',
            dataIndex: 'item_strength',
            key: 'itemstrength',
        },
        {
            title: 'Form',
            dataIndex: 'item_presentation',
            key: 'item_presentation',
        },
        {
            title: 'Approved Cost',
            dataIndex: 'cost',
            key: 'cost',
        },
        {
            title: 'Proposed Costs',
            children: [
                {
                    title: 'Facility',
                    dataIndex: 'cost_facility',
                    key: 'cost_facility',
                },
                {
                    title: 'HMO',
                    dataIndex: 'cost_hmo',
                    key: 'cost_hmo',
                }
            ]
        },
        {
            title: 'Status',
            dataIndex: 'costable_pricing_status_name',
            key: 'costable_pricing_status_name',
        },
        {
            key: 'action',
            render: (_, record) => ( [4].includes(Number(user.role.staffRoleId)) ?
                <Space size="middle" direction="vertical">
                    <Menu items={dropdownItems} onClick={e => menuAction(e, _, record)} trigger={'click'}>
                        Actions
                    </Menu>
                </Space> : null
            ),
        },
    ];

    const dropdownItems = [
        {
            key: 0,
            label: 'Action',
            icon: <MenuUnfoldOutlined />,
            disable: deleting || editing,
            children: [
                {
                    key: 1,
                    label: 'Accept Facility Cost',
                    icon: <CheckSquareTwoTone />,
                    disable: deleting || editing
                },
                {
                    key: 2,
                    label: 'Propose Cost',
                    icon: <EditTwoTone />,
                    disable: deleting || editing
                },
                {
                    key: 3,
                    label: 'Messages',
                    icon: <MessageTwoTone />,
                    disable: deleting || editing
                }
            ]
        },
    ];

    const columns_ = [
        {
            title: 'Facility Name',
            dataIndex: 'facility_name',
            key: 'facility_name',
            render: (text, record) => <Button onClick={e => loadDataForFacility(e, record)} style={{color: '#1677ff'}} type="link">{text}</Button>,
        },
        {
            title: 'Facility Type',
            dataIndex: 'facility_type',
            key: 'facility_type',
        },
        {
            title: 'Facility State',
            dataIndex: 'facility_state',
            key: 'facility_state',
        },
        {
            title: 'Facility City',
            dataIndex: 'facility_city',
            key: 'facility_city',
        },
        {
            title: 'Facility Email',
            dataIndex: 'facility_email',
            key: 'facility_email',
        },
        {
            title: 'Proposions',
            children: [
                {
                    title: <CheckSquareFilled style={{color: '#237804', fontSize: '25px', fontWeight: 'bolder'}} title="Approved"/>,
                    dataIndex: 'approved',
                    key: 'approved',
                },
                {
                    title: <HourglassOutlined style={{color: '#ffc53d', fontSize: '25px', fontWeight: 'bolder'}} title="Pending"/>,
                    dataIndex: 'pending',
                    key: 'pending',
                },
                {
                    title: <CloseSquareFilled  style={{color: '#a8071a', fontSize: '25px', fontWeight: 'bolder'}} title="Reject"/>,
                    dataIndex: 'rejected',
                    key: 'rejected',
                }
            ]
        }    
    ];

    const saveChange = (record, Changes) => {
        if(Changes?.cost_hmo === record.cost_hmo){
            notification.info({message: 'No Changes were made!'})
            return
        }

        setSubmittingEdit(true)
        myRequest('post', 'editPricingItem', record)
        .then(response => response.data)
        .then(data => {
            if(!data?.error){
                notification.success({message: data?.message})
                setFacilityFiledItems(facilityFiledItems.map(item => item.item_code === record.item_code? data.data : item))
            }else{
                notification.error({message: data?.message})
            }
        }).finally(() => setSubmittingEdit(false))
    }

    useEffect(() => {
        if(showFacilityFilings){
            fetchCostable(focusFacility)
        }
    }, [showFacilityFilings]) //eslint-disable-line react-hooks/exhaustive-deps

    const proposePrice = (item, record) => {
        setItemToEdit(record)

        confirm({
            width: 400,
            title: <>Propose Price for {record.item_code} - <em>{focusFacility?.facility_name}</em></>,
            icon: <EditTwoTone />,
            content: <>
                <Space direction="horizontal">
                    <Typography.Text>Price:</Typography.Text>
                    <InputNumber value={record.cost_hmo} disabled={submittingEdit} onChange={e => record = {...record, cost_hmo: e}} />
                </Space>
            </>,
            okText: 'Save Change',
            cancelText: 'Close',
            onCancel: () => {setItemToEdit({})},
            onOk: () => saveChange(record, itemToEdit)
        });
    }

    const showMessage = async (e, record) => {
        setMessagingItem(record)
        setMessaging(true)
    }

    const loadDataForFacility = (e, record) => {
        e.preventDefault()
        setShowFacilityFilings(true)
        setFocusFacility({facility: record.facility_id})
        setFocusFacilityData(record)
    } 

    return (
      <div  style={{width: '100%', margin: "20px 0 5px 0", padding: 0}}>
        <Row gutter={[12, 12]} justify={'space-evenly'}>
            {
                showFacilityFilings && (
                    <Col span={6} md={6} sm={12} lg={18}>
                        <Typography.Title type="secondary">{focusFacilityData?.facility_name} {focusFacilityData?.facility_type? '-' : ''} {focusFacilityData?.facility_type}</Typography.Title>
                    </Col>
                )
            }
            <Col span={3} md={3} sm={6} lg={3}>
                <Button style={{ width: "100%" }} onClick={() => showFacilityFilings? fetchCostable(focusFacility) : fetFacilities()} disabled={filedItemsLoading} loading={filedItemsLoading}>
                    Load Data
                </Button>
            </Col>
            {
                showFacilityFilings && (
                    <Col span={3} md={3} sm={6} lg={3}>
                        <Button type="primary" danger style={{ width: "100%" }} onClick={() => {setShowFacilityFilings(false); setFocusFacility({}); setFocusFacilityData({})}} disabled={filedItemsLoading} loading={filedItemsLoading}>
                            Close
                        </Button>
                    </Col>
                )
            }
        </Row>
        {
            showFacilityFilings &&
            <Space direction="vertical" style={{width: '100%', margin: '20px 0 20px 0'}}>
                <Space direction="vertical" gutter={[12, 12]} justify={'space-between'} style={{width: '100%'}}>
                    <Table columns={columns} dataSource={removeDuplicates(facilityFiledItems, 'item_code')} />
                </Space>
            </Space>
        }{
            !showFacilityFilings &&
            <Space direction="vertical" style={{width: '100%'}}>
                <Space direction="vertical" justify={'space-between'} style={{width: '100%'}}>
                    <Table columns={columns_} dataSource={removeDuplicates(n_data, 'facility_id')}/>
                </Space>
            </Space>
        }
        {
          messaging && 
          <PAQuery show={messaging} data={messagingItem} close={() => setMessaging(false)} />
        }
      </div>
    )
}

const PAQuery = ({ show, data, close }) => {
    const [queries, setQueries] = useState([])
    const [loading, setLoading] = useState(false);
    const [sending, setsending] = useState(false);
    const [visible, setVisible] = useState(show);
    const [messageText, setMessageText] = useState('');
  
    const [messageData, ] = useState({ 
      relation: 'costable_pricing', 
      marker: data?._id, 
      source: 0, 
      facility_type: 0
    });
  
    const [, setFirstLoad] = useState(true);
    const [, setReading] = useState(false);
  
    let interval = useRef();
  
    useEffect(() => {
        let intervalId; // Variable to store the interval ID
    
        const fetchAndUpdateQueries = () => {
            fetchQueries(visible).then(res => {
                setLoading(false);
            });
        };
    
        if (visible) {
            setLoading(true);
            localStorage.setItem(config.key.q_reading, 1);
            fetchAndUpdateQueries();
            intervalId = setInterval(fetchAndUpdateQueries, 5000);
        } else {
            setReading(false);
            localStorage.setItem(config.key.q_reading, 0);
            clearInterval(intervalId); // Clear the interval
        }
    
        return () => {
            clearInterval(intervalId); // Cleanup: Clear the interval on unmount or when `visible` changes
        };
    }, [visible]); //eslint-disable-line react-hooks/exhaustive-deps
    
  
    useEffect(() => {
      const queriesToUpdate = queries.filter(j => !Number(j.status) && !Number(j.source))
      .map(i => {
        return {
          id: i.id
        }
      })
  
      if(visible && queriesToUpdate.length){
        myRequest('post', `updateMessage`, queriesToUpdate)
        .catch(e => console.log(e.msg ?? e.message ))
        .finally(setFirstLoad(false));
      }
    }, [visible, queries])
  
    const handleOk = () => {
      setsending(true);
      myRequest('post', `message`, {content: messageText, ...messageData})
      .then(res => res.data)
      .then(body => {
        console.log(body.data)
        if(!body.error){}
        setsending(false);
        setMessageText('');
      })
    };
  
    const handleCancel = () => {
      clearInterval(interval.current);
      setVisible(false);
      setReading(false);
      close()
    };
  
    const composeMessage = e => {
      const mess = e.target.value;
      setMessageText(mess)
    }
  
    const fetchQueries = async (x) => {
      for await (const data of (fetchPaginatedData('post', 'messages', messageData))) {
        setQueries(d => [...updateChangedObjects(d, data)])
      }
    }
    
    return (
      <>
        <Drawer
          open={show}
          title={
            <>
              <Typography.Title level={5} style={{margin: '0 auto'}}>
                Messages: {data?.item_code } - {data?.item_name}
              </Typography.Title>
            </>
          }
          onClose={handleCancel}
          footer={[
            <Row justify="space-between" align="bottom">
              <Col span={20}>
                <TextArea
                  rows={3} value={messageText} placeholder='Write Full Description of the Query....' onChange={composeMessage} id={'message'}/>
              </Col>
              <Col>
                <Button
                  key="back" onClick={handleOk} style={{ color: 'skyblue', fontSize: '1em' }} disabled={!messageText.length || sending}
                > Send </Button>
              </Col>
            </Row>
          ]}
          width={500}
        >
          <Space gutter={[8, 16]} direction={'vertical'} id='chatContainer' style={{width: '100%'}}>
            {(loading)?
              <Col>
                <Spin size="large" />
              </Col>
              :(!queries.length)?
                <Empty description={<span>
                  No Converation
                </span>} />:
                <>
                  {queries.sort((a, b) => Date.parse(a.date) - Date.parse(b.date)).map((q, i) => 
                    <Row id={`query_${i}`} 
                      justify={(!Number(q.source))? 'end' : 'start'}
                    >
                      <Col span={16}
                        style={{
                          display: 'flex', 
                          flexDirection: 'column', 
                          lineHeight: '18px',
                          width: 'fit-content', 
                          color: `${Number(q.source)? 'red': 'green'}`, 
                          border: `${Number(q.source)? '1px solid red': '1px solid green'}`, 
                          borderRadius: '10px' , padding: '10px'
                        }}
                      >
                        <p>{q.content}</p>
                        <span style={{textAlign: 'right', justifySelf: 'flex-end'}}>{(new Date(q.date)).toLocaleString()}</span>
                      </Col>
                    </Row>
                  )}
                </>
            }
          </Space>
        </Drawer>
      </>
    );
};

const Costing = () => {  
    const { user } = useAuth();
    const navigator = useNavigate();

    useEffect(() => {
        if(user){
            if(![1,3,4].includes(Number(user.role.staffRoleId))) {
                navigator('/login')
            }
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Row gutter={16}>
                <Col span={24}>
                    <Pricing />
                </Col>
            </Row>
        </>
    )
}

export default Costing;