import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const renderButton = ({ 
    type = 'default',
    label, 
    key, 
    actionType, 
    loadingState,
    newData = {}, 
    style = {}, 
    danger = false, 
    isDisabled = false, 
    onClick,
    icon = null,
    ghost = null,
    suffix = null,
    prefix = null
  }) => (
    <Button
      type={type}  // `undefined` if type is falsy, allowing default button styles
      danger={danger}
      disabled={isDisabled}
      style={style}
      icon={icon}
      ghost={ghost}
      suffix={suffix}
      prefix={prefix}
      onClick={onClick ? onClick : () => updateData(actionType, newData)}  // Use custom onClick if provided
    >
      {loadingState ? <><LoadingOutlined /> {label}</>: label}
    </Button>
  );
  

export const renderButtonAlt = ({ 
    type = 'danger', 
    label, 
    key, 
    actionType, 
    newData = {}, 
    style = {}, 
    danger = false, 
    isDisabled = false 
  }) => (
    <Button
      type={type || undefined}  // `undefined` if type is falsy, allowing default button styles
      danger={danger}
      disabled={isDisabled || Object.values(loadingState).some(Boolean)}
      style={style}
      onClick={() => updateData(actionType, newData)}
    >
      {loadingState[key] ? <LoadingOutlined /> : label}
    </Button>
  );
  