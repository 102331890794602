import React, { useState, useEffect } from "react";
import { useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom"
import { 
  Select, Button,  Input, message, Row, Col, 
  Empty, Modal, notification, Menu,
  Switch,  Typography, Divider,
} from 'antd';
import {  UserOutlined, } from '@ant-design/icons';
import { useAuth } from '../../assets/utils/useAuth';

import './index.css';
import lib from "../../assets/utils/lib";
import Helpers from "../../assets/utils/Helpers";

const ClaimsRequestHook = props => {
  const {set, user} = useAuth();
  const [facilityUsers, setFacilityUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigator = useNavigate();
  const dispatcher = useDispatch();
  
  useEffect(() => {
    if(user){
      if(![1,6,7].includes(Number(user.role.staffRoleId))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission',
          placement: 'topLeft'
        })
        navigator('/login')
      }
    }else{
      navigator('/login')
    } 
  });

  useEffect(() => {
    loadUsers();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   loadUsers();
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const loadUsers = async () => {
    const data = (await lib.readUser({id: user.id}))?.data
    // console.log(data)
    if(data){
      setFacilityUsers(data?.data);
    }
  }

  const updateSuerState = async (e, u) => {
    const payload = {id: u,  payload: Number(e)}
    setLoading(true);
    
    notification.info({
      message: 'Updating Profile',
      key: 1,
      duration: 2,
      placement: 'topLeft'
    })

    const data = await lib.updateProfile(payload);
    // console.log(data)
    if(data){
      if(Number(data?.data?.error) === 1){
        notification.error({
          message: data?.data?.message,
          description: data?.data?.description,
          duration: 3,
          placement: 'topLeft'
        })
        return;
      }
      else if(Number(data?.data?.error) === 0){
        notification.success({
          message: data?.data?.message,
          description: data?.data?.description,
          duration: 3,
          placement: 'topLeft'
        })
      }
      await loadUsers()
      setLoading(false);
    }    
  }

  return (
    <>
      {
        (user)?
          <>
            <Row style={{width: '100%', height: 'calc(100vh - 63.78px)'}}>
              <Col span={5} style={{height: '100%'}}>
                <Menu mode="vertical" items={[
                  {
                    label: 'Users',
                    key: 'users',
                    icon: <UserOutlined />
                  }
                ]} style={{width: '100%', height: '100%'}} />
              </Col>
              <Col span={19} style={{height: '100%', overflowY: 'auto'}}>
                <Row style={{width: '100%', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto'}} justify='center'>
                  <Col span={20}>
                    <Divider orientation="left" orientationMargin="0">
                      <Typography.Title level={5} >
                        Current Users
                      </Typography.Title>
                    </Divider>
                    {
                      (facilityUsers?.length)?
                        <>
                          {
                            facilityUsers?.map(u => 
                              <>
                                <Row style={{width: '100%'}}>
                                  <Col span={6}>
                                    <Row>
                                      <Col span={24}>
                                        Staff Id:
                                      </Col>
                                      <Col span={24}>
                                        {u.staffId}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={6}>
                                    <Row>
                                      <Col span={24}>
                                        Role:
                                      </Col>
                                      <Col span={24}>
                                        {u.role.staffRoleName}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={6}>
                                    <Row>
                                      <Col span={24}>
                                        Department:
                                      </Col>
                                      <Col span={24}>
                                        {u.department.name}
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={6}>
                                    <Row>
                                      <Col span={10}>
                                        Status:
                                      </Col>
                                      <Col span={10}>
                                        {
                                          (Number(u.status) === 0)? 
                                          <>
                                            <span style={{color: 'grey'}}>
                                              Inactive
                                            </span>
                                          </>:
                                          <>
                                            <span style={{color: 'green'}}>
                                              Active
                                            </span>
                                          </>
                                        }
                                      </Col>
                                      {
                                        ([1,6,7].includes(Number(user?.role?.staffRoleId)))?
                                          <Col span={6}>
                                            <Switch defaultChecked={ (Number(u.status) === 0)? false: true } onChange={ (e) => updateSuerState(e, u.id) } disabled={loading}/>
                                          </Col>:null
                                      }
                                      
                                    </Row>
                                  </Col>
                                </Row>
                              </>
                            )
                          }
                        </>:
                        <>
                          <Empty 
                            description={
                              <span>
                                There are no claims in this category
                              </span>
                            }
                            style={{padding: 50, width: '100%'}}
                          />
                        </>
                    }
                  </Col>
                </Row>
                <Row style={{width: '100%'}} justify='center'>
                  <Col span={20}>
                    <Divider />
                    <FormDisabledDemo callback={ () => loadUsers() } />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>:null
      }
      
    </>
  )
}

const FormDisabledDemo = ({callback}) => {  
  const [formData, setFormData] = useState({});
  const [visible, setVisible] = useState(false);
  const [, setloading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect( () => {
    getDepts()
    getRoles()
  }, [])

  const getDepts = async () => {
    const data =  await lib.departments()
    if(data) {
      setDepartments(data?.data?.data?.map(d => ({value: d.id, key: d.name})))
    }
  }

  const getRoles = async () => {
    const data =  await lib.roles()
    if(!data?.data?.data?.error) {
      setRoles(data?.data?.data?.map(d => ({value: d.staffRoleId, key: d.staffRoleName})))
    }
  }

  const showForm = () => {
    setVisible(true)
  }

  const hideForm = () => {
    setVisible(false)
  }

  const submit = async () => {
    setloading(true)
    const key = 'login';
    if(Object.keys(formData).length < 4) {
      notification.error({
        message: 'Missing Field',
        description: 'make sure all the fields were appropriately filled', 
        duration: 2,
        placement: 'topLeft'
      })
      return;
    }

    message.loading({ content: 'Loading...', key });
  
    let reqData = await lib.createUser(formData)

    if(!reqData) return;

    if (reqData?.data?.error) {
      notification.error({ message: reqData.data.message, duration: 2, placement: 'topLeft'});
    }else{
      notification.success({ message: reqData.data.message, duration: 2, placement: 'topLeft'});
      setTimeout(() => {
          callback();
          hideForm();
      },  500)
    }
    setloading(false)
  }

  return (
    <>
      <Button type="primary" onClick={showForm}>
        Add User
      </Button>
      <Modal
        title="User Creation Form"
        visible={visible}
        onOk={submit}
        onCancel={hideForm}
        destroyOnClose
        okText="Create User"
        cancelText="Close"
        style={{marginTop: -100}}
      >
        <div>
          <Row span={[8, 12]}>
            <Col span={5}>Staff ID</Col>
            <Col span={19}>
              <Input  onChange={e => setFormData(d => ({...d, staffId: e.target.value}))}/>
            </Col>
          </Row>
          <Row span={[8, 12]}>
            <Col span={5}>Password</Col>
            <Col span={19}>
              <Input.Password onChange={e => setFormData(d => ({...d, password: e.target.value}))}/>
            </Col>
          </Row>
          <Row span={[3, 12]}>
            <Col span={5}>Department</Col>
            <Col span={19}>
              <Select onChange={e => setFormData(d => ({...d, departmentId: e}))} style={{width: '100%'}}>
                {
                  departments.map(d => <Select.Option value={d.value}>{d.key}</Select.Option>)
                }
              </Select>
            </Col>
          </Row>
          <Row span={[3, 12]}>
            <Col span={5}>Role</Col>
            <Col span={19}>
              <Select onChange={e => setFormData(d => ({...d, roleId: e}))} style={{width: '100%'}}>
                {
                  roles.map(d => <Select.Option value={d.value}>{d.key}</Select.Option>)
                }
              </Select>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};


const ClaimsHook = () => {  
  return (
    <>
      <>
        {
          <ClaimsRequestHook />
        }
      </>
    </>
  )
}

export default ClaimsHook;