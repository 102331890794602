import React, { useEffect, useRef } from 'react';
import useLocalStorage from './useLocal';
import config from '../../assets/utils/config';
import { notification } from 'antd';

const AuthContext = React.createContext();
export function useAuth() {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be called within a AuthProvider');
    }
    return context;
}

export default function AuthProvider({ children }) {
    const [user, setUser] = useLocalStorage(config.key.user);
    const [token, setToken, ClearValues] = useLocalStorage(config.key.token);
    const timerRef = useRef();

    const set = async (data) => {
        setToken(data?.jwt);
        setUser(data);
    }

    const unset = async () => {
        setToken(null);
        setUser(null);
        ClearValues();
    }

    useEffect(() => {
        if(user){
            timerRef.current = setInterval(() => {
                if((Date.now() > new Date(user.expireAt.date))){
                    if(timerRef.current) clearInterval(timerRef.current)
                    unset()
                    notification.info({
                        message: <>
                            <strong>Session Timed Out</strong><br></br>
                            Your Current Session Has Expired, Kindly Login To Continue With Your Activities.
                        </>,
                        placement: 'topLeft'
                    });
                    setTimeout(() => {
                        window.location.assign('/login');
                        window.location.reload(1)
                    }, 2000);
                }
            }, 1000)
        }
    }, [user]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AuthContext.Provider value={{ set, unset, token, user }}>
            {children}
        </AuthContext.Provider>
    )
}