import React, { useState } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined, LoadingOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';

const MyDropDown = ({ menuItems, ButtonType=null, icon, textContent = '', disabled=false }) => {
    const [dropdownOpened, setDropdownOpened] = useState(false)
    // Recursive function to render menu items and their children
    const renderMenuItems = (items) => {
        return items.map((item) => {
            if (item.children && item.children.length > 0) {
                return (
                    <Menu.SubMenu
                        key={item.key}
                        title={item.label}
                        icon={<RightOutlined />}
                    >
                        {renderMenuItems(item.children)}
                    </Menu.SubMenu>
                );
            }
            return (
                <Menu.Item key={item.key} onClick={item.onClick}>
                    {item.label}
                </Menu.Item>
            );
        });
    };

    const menu = (
        <Menu>
            {renderMenuItems(menuItems)}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']} onOpenChange={setDropdownOpened}>
            <Button type={ButtonType} icon={icon} disabled={disabled}>
                {textContent} {dropdownOpened? <UpOutlined /> :<DownOutlined />}
            </Button>
        </Dropdown>
    );
};

export default MyDropDown;
