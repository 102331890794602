import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  diagnosis: [],
  diagnosis_final: [],
  data: []
};
export const preAuth = createSlice({
  name: 'preauths',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    add_item: (state, action) => {
      state.items.push(action.payload.items);
      state.data.push(action.payload.data);
    },
    add_diagnosis: (state, action) => {
      state.diagnosis = action.payload;
    },
    add_diagnosis_final: (state, action) => {
      state.diagnosis_final = action.payload;
    },
    reset_items: (state, action) => {
      state.items = action.payload.items;
      state.data = action.payload.data;
    },
    clean_items: (state, action) => {
      state.items = [];
    },
    remove_diagnosis: (state, action) => {
      state.diagnosis = [];
    },
    remove_diagnosis_final: (state, action) => {
      state.diagnosis_final = [];
    },
  }
});

export const { 
  add_item, remove_item, reset_items, 
  add_diagnosis, remove_diagnosis, 
  clean_items, add_diagnosis_final ,
  remove_diagnosis_final
} = preAuth.actions;

export const selectItems = (state) => state.preAuth.items;
export const selectData = (state) => state.preAuth.data;
export const selectDiagnosis = (state) => state.preAuth.diagnosis;
export const selectDiagnosisFinal = (state) => state.preAuth.diagnosis_final;


export default preAuth.reducer;
