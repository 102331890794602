import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../assets/utils/useAuth';
import lib from '../../assets/utils/lib';
import './org.css'

import { Table, Modal, Form, Input, Button, FloatButton, Select, Typography, notification, Col, Row, Divider, Space, Empty, Statistic, InputNumber, Card, Tooltip, Radio } from 'antd';
import { PlusOutlined, EditOutlined, PoweroffOutlined, ReloadOutlined, MinusCircleOutlined, DeleteOutlined, EyeOutlined, ShareAltOutlined, SyncOutlined, SettingOutlined, CheckCircleOutlined, CloseCircleOutlined, AppstoreOutlined, DatabaseOutlined } from '@ant-design/icons';
import { Dna, ProgressBar, ThreeCircles } from "react-loader-spinner";
import { keysHaveEmptyValues } from "../../assets/utils/Helpers";
import CountUp from 'react-countup';
import CurrencyFormat from "react-currency-format";
import { RenewOrgPolicy } from "../compoinents/policyRenew";
import { states, durationOptions } from "../../assets/utils/config";

import Masonry from 'react-masonry-css'

const { Option } = Select;
const { TextArea } = Input;
const { Meta } = Card;

const formatter = (value) => <CountUp end={value} separator="," />;

function Organizations(props) {
  const { user } = useAuth();
  const extended = props.extended !== undefined ? props.extended : true;
  const timerId = useRef();
  const timerIdPlans = useRef();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (![1, 5].includes(Number(user.role.staffRoleId))) {
        navigator('/login')
      } else {
        clearInterval(timerId);
        loadOrganization();

        timerId.current = setInterval(() => { loadOrganization(true); }, 5000);
      }
    }
    return () => clearInterval(timerId.current);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visiblePlanManagement, setVisiblePlanManagement] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleRenew, setVisibleRenew] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingPLans, setLoadingPlans] = useState(false);

  const [visisbleAddPlan, setVisibleAddPlan] = useState(false);

  const fieldsUpdate = ['org_name', 'org_email', 'org_address', 'org_state', 'org_city']
  const fieldsCreate = ['org_name', 'org_email', 'org_address', 'org_state', 'org_city', 'org_social_no']
  const fieldsRenew = ['extension', 'duration']

  const [formCreate, setFormCreate] = useState({});
  const [formUpdate, setFormUpdate] = useState({});
  const [formManage, setFormManage] = useState({});
  const [formRenew, setFormRenew] = useState({});

  const [plans, setPlans] = useState([]);
  const [orgPlans, setOrgPlans] = useState([]);

  const currentlyBeingRenewed = useRef(null);

  const [view, setView] = useState('tile');

  useEffect(() => {
    if (visiblePlanManagement) {
      clearInterval(timerId);

      getPlans();
      loadOrgPlans();

      timerIdPlans.current = setInterval(() => { getPlans(); loadOrgPlans(1); }, 5000);

      return () => clearInterval(timerIdPlans.current);
    }
  }, [visiblePlanManagement]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadOrgPlans = (flag = 0) => {
    setLoadingPlans(!Boolean(flag));
    lib.orgPlans({ id: formManage?.org_id })
      .then(response => response.data)
      .then(data => {
        setOrgPlans(data?.data)
      })
      .finally(() => setLoadingPlans(false))
  }

  const loadOrganization = async (flag = 0) => {
    if (!flag) {
      setLoading(true)
    }

    const data = (await lib.orgs({}))?.data;
    if (data && !Number(data?.error)) {
      setUsers(data?.data)
    } else {
      notification.error({
        message: data?.message || 'Error'
      })
    }

    setLoading(false);
  }

  const handleEdit = (record) => {
    setFormUpdate(record)
    setVisibleEdit(true);
  };

  const handlePlanManagment = (record) => {
    setFormManage({ ...record })
    setVisiblePlanManagement(true);
  };

  const handleUpdate = async () => {
    console.log(formUpdate)
    if (keysHaveEmptyValues(formUpdate, fieldsUpdate)) {
      notification.error({
        message: "Please fill all the fields", placement: 'topLeft'
      })
      return;
    }
    const data = await lib.updateOrgData(formUpdate);

    if (!data?.data?.error) {
      handleCancel();
      loadOrganization();
    } else {
      notification.error({
        message: data?.data?.message, placement: 'topLeft'
      })
    }
  };

  const handleRenew = async () => {
    if (keysHaveEmptyValues(formRenew, fieldsRenew)) {
      notification.error({
        message: "Please fill all the fields", placement: 'topLeft'
      })
      return;
    }

    if (!currentlyBeingRenewed.current) {
      notification.error({ message: "Empty organization data", placement: 'topLeft' });
      return;
    }

    const updatValues = { org_social_no: currentlyBeingRenewed.current.org_social_no, validity_period: `${formRenew.extension} ${formRenew.duration}` }
    const data = await lib.updateOrgDate(updatValues);
    if (!data?.data?.error) {
      notification.success({
        message: data?.data?.message, placement: 'topLeft'
      })
      handleCancel();
      loadOrganization();
    } else {
      notification.error({
        message: !data?.data?.message, placement: 'topLeft'
      })
    }
  };

  const handleStatus = async (record) => {
    record.org_status = !parseInt(record.org_status) ? 1 : 0;
    const data = (await lib.updateOrgData(record))?.data;
    if (Number(data?.error) === 0) {
      handleCancel();
      notification.success({
        message: data?.message, placement: 'topLeft'
      })
      loadOrganization();
    }
  };

  const handleAdd = async () => {
    console.log(formCreate)
    if (keysHaveEmptyValues(formCreate, fieldsCreate)) {
      notification.error({
        message: "Please fill all the fields", placement: 'topLeft'
      })
      return;
    }
    const data = (await lib.createOrg(formCreate))?.data;
    if (Number(data?.error) === 0) {
      notification.success({ message: data?.data?.message })
      loadOrganization();
      handleCancel();
    }
  };

  const handleManage = async () => {
    setVisiblePlanManagement(false);
  };

  // const renewHandler = (record) => {
  //   currentlyBeingRenewed.current = record;
  //   setVisibleRenew(true);
  // }

  const handleCancel = () => {
    setVisibleEdit(false);
    setVisibleRenew(false);
    setVisibleCreate(false);
    setVisiblePlanManagement(false)
    setFormCreate({});
    setFormUpdate({});
    setFormRenew({});
    setFormManage({})
    currentlyBeingRenewed.current = null;
  };

  const getPlans = () => {
    lib.getPlans()
      .then((resp) => resp.data)
      .then((planTypes) => planTypes?.data)
      .then(render => setPlans(render))
  }

  const showAddplan = () => {
    setVisibleAddPlan(true)
    form.setFieldsValue({
      plans: [],
    });
  }

  const handleItemSelection = e => {
    console.log(form.getFieldsValue())
  }

  const handleAddPlan = () => {
    const payload = {};
    payload['org_id'] = formManage?.org_id
    payload['plans'] = []

    form.getFieldsValue()['plans'].forEach((item, index) => {
      if (!Object.keys(item).includes('family_cost')) item['family_cost'] = 0
      payload['plans'].push(item);
    })

    setLoadingPlans(true)
    lib.submitOrgPlans(payload)
      .then(response => response.data)
      .then(data => {
        if (data?.error) {
          notification.error({ message: data?.message, placement: 'topLeft' })
        } else {
          notification.success({ message: data?.message, placement: 'topLeft' })
          loadOrgPlans()
          setVisibleAddPlan(false)
        }
      })
      .finally(() => setLoadingPlans(false))
  }

  const removeOrgPlan = plan => {
    console.log(plan)
    setLoadingPlans(true)
    lib.removeOrgPlans(plan)
      .then(response => response.data)
      .then(data => {
        notification.success({ message: data?.message, placement: 'topLeft' })
      })
      .finally(() => {
        loadOrgPlans(1)
        setLoadingPlans(false)
      })

  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'org_name',
      key: 'org_name',
      render: (text, record) => <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => navigate(`/corporations/users/${record.hash}`, { state: record })}>{text}</span>
    },
    {
      title: 'Social No.',
      dataIndex: 'org_social_no',
      key: 'org_social_no',
    },
    // {
    //   title: 'Email',
    //   dataIndex: 'org_email',
    //   key: 'org_email',
    // },
    // {
    //   title: 'Address',
    //   dataIndex: 'org_address',
    //   key: 'org_address',
    // },
    // {
    //   title: 'State',
    //   dataIndex: 'org_state',
    //   key: 'org_state',
    // },
    // {
    //   title: 'Joined Date',
    //   dataIndex: 'org_joined_date',
    //   key: 'org_joined_date',
    // },
    {
      title: 'Renewal Date',
      dataIndex: 'org_renew_date',
      key: 'org_renew_date',
      render: (__, record) => __?.split(' ')[0]
    },
    {
      title: 'Expiry Date',
      dataIndex: 'org_expiry_date',
      key: 'org_expiry_date',
      render: (__, record) => __?.split(' ')[0]
    },
    {
      title: 'Status',
      dataIndex: 'org_status',
      key: 'org_status',
      render: (__, record) => (parseInt(record.org_status) ? 'Enabled' : 'Disabled')
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (__, record) => (
        <span style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Button type="dashed" icon={<EditOutlined />} onClick={() => handleEdit(record)}>Edit</Button>
          <Button type="dashed" icon={<EditOutlined />} onClick={() => handlePlanManagment(record)}>Manage Plans</Button>
          <RenewOrgPolicy data={record} action={loadOrganization} viewType="table" />
          <Button type='dashed' danger={parseInt(record.org_status)} icon={<PoweroffOutlined />} onClick={() => handleStatus(record)}>{parseInt(record.org_status) ? 'Disable' : 'Enable'}</Button>
        </span>
      ),
    },
  ];

  const columnsMinimized = [
    {
      title: 'Name',
      dataIndex: 'org_name',
      key: 'org_name',
      render: (text, record) => <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => navigate(`/corporations/users/${record.hash}`, { state: record })}>{text}</span>
    },
    {
      title: '# Active Policy',
      dataIndex: 'active_policies',
      key: 'active_policies',
    },
    {
      title: 'Contribution',
      dataIndex: 'orgContributions',
      key: 'orgContributions',
      render: (__, record) => <Statistic prefix='&#8358;' title={<strong></strong>} value={__} precision={2} formatter={formatter} />
    }
  ];

  const [form] = Form.useForm();

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

  const handleToggle = (e) => {
    setView(e.target.value);
  };

  return (
    <div style={{ paddingTop: 5 }}>
      {
        loading ?
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
            {extended ?
              <>
                <ThreeCircles
                  height="100"
                  width="100"
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor=""
                  middleCircleColor=""
                />
              </> : <>
                <Dna
                  visible={true}
                  height="100"
                  width="100"
                  ariaLabel="dna-loading"
                  wrapperStyle={{}}
                  wrapperClass="dna-wrapper"
                />
              </>
            }
          </div> :
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {
              extended &&
              <>
                <FloatButton
                  shape="circle"
                  type="primary"
                  style={{
                    right: 94,
                    cursor: 'pointer'
                  }}
                  onClick={() => setVisibleCreate(true)}
                  icon={<PlusOutlined />}
                />
                <FloatButton
                  shape="circle"
                  type="primary"
                  style={{
                    right: 188,
                    cursor: 'pointer'
                  }}
                  onClick={() => loadOrganization()}
                  icon={<ReloadOutlined />}
                />
              </>
            }
            <div>
              <div style={{display: 'flex', width: '100%', justifyContent: 'right'}}>
                <Radio.Group onChange={handleToggle} value={view}>
                  <Radio.Button value={'tile'}>
                    {view === 'tile' ? <AppstoreOutlined style={{ color: 'green' }} /> : <AppstoreOutlined />}
                  </Radio.Button>
                  <Radio.Button value={'table'}>
                    {view === 'table' ? <DatabaseOutlined style={{ color: 'green' }} /> : <DatabaseOutlined />}
                  </Radio.Button>
                </Radio.Group>
              </div>
              {extended ?
                <>
                  {view === 'tile'?
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {users.map(u => <HoverCard data={u} editHandler={handleEdit} manageHandle={handlePlanManagment} renewHandler={RenewOrgPolicy} statusHandler={handleStatus} />)}
                  </Masonry> : <Table columns={columns} dataSource={users} rowKey="id" pagination={false} />
                  }
                </>
                : <Table columns={columnsMinimized} dataSource={users} rowKey="id" pagination={false} />
              }
            </div>
          </div>
      }

      <Modal
        title="Add Organization"
        open={visibleCreate}
        onOk={handleAdd}
        onCancel={handleCancel}
        forceRender
      >
        <Row gap={6}>
          <Col span={8}>Name</Col>
          <Col span={16}>
            <Input onChange={(e) => setFormCreate(d => ({ ...d, org_name: e.target.value }))} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>Email</Col>
          <Col span={16}>
            <Input onChange={(e) => setFormCreate(d => ({ ...d, org_email: e.target.value }))} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>Social Code</Col>
          <Col span={16}>
            <Input onChange={(e) => setFormCreate(d => ({ ...d, org_social_no: e.target.value }))} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>Address</Col>
          <Col span={16}>
            <TextArea onChange={(e) => setFormCreate(d => ({ ...d, org_address: e.target.value }))} rows={5} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>State</Col>
          <Col span={16}>
            <Select options={states} onChange={(e) => setFormCreate(d => ({ ...d, org_state: e }))} style={{ width: '100%' }} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>City</Col>
          <Col span={16}>
            <Input onChange={(e) => setFormCreate(d => ({ ...d, org_city: e.target.value }))} />
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Organizational Premium Renewal"
        open={visibleRenew}
        onOk={handleRenew}
        onCancel={handleCancel}
        forceRender
      >
        <Typography.Paragraph>Renewal of Organizational Premium For {currentlyBeingRenewed.current?.org_name}</Typography.Paragraph>
        <Row gap={6}>
          <Col span={8}>Extension</Col>
          <Col span={16}>
            <Input onChange={(e) => setFormRenew(d => ({ ...d, extension: e.target.value }))} value={formRenew?.extension} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>Period</Col>
          <Col span={16}>
            <Select value={formRenew?.duration} name="duration" options={durationOptions} onChange={(e) => setFormRenew(d => ({ ...d, duration: e }))} style={{ width: '100%' }} />
          </Col>
        </Row>
      </Modal>

      <Modal
        title="Edit Detail"
        open={visibleEdit}
        onOk={handleUpdate}
        onCancel={handleCancel}
        forceRender
      >
        <Row gap={6}>
          <Col span={8}>Name</Col>
          <Col span={16}>
            <Input value={formUpdate?.org_name} onChange={(e) => setFormUpdate(d => ({ ...d, org_name: e.target.value }))} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>Email</Col>
          <Col span={16}>
            <Input value={formUpdate?.org_email} onChange={(e) => setFormUpdate(d => ({ ...d, org_email: e.target.value }))} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>Address</Col>
          <Col span={16}>
            <TextArea value={formUpdate?.org_address} onChange={(e) => setFormUpdate(d => ({ ...d, org_address: e.target.value }))} rows={5} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>State</Col>
          <Col span={16}>
            <Select options={states} value={formUpdate?.org_state} onChange={(e) => setFormUpdate(d => ({ ...d, org_state: e }))} style={{ width: '100%' }} />
          </Col>
        </Row>
        <Row gap={6}>
          <Col span={8}>City</Col>
          <Col span={16}>
            <Input value={formUpdate?.org_city} onChange={(e) => setFormUpdate(d => ({ ...d, org_city: e.target.value }))} />
          </Col>
        </Row>
      </Modal>

      <Modal
        title={<>Plan Management - <em>{formManage?.org_name}</em></>}
        open={visiblePlanManagement}
        onOk={handleManage}
        onCancel={handleCancel}
        forceRender
        width={900}
        footer={[
          <Button type="dashed" danger onClick={showAddplan} icon={<PlusOutlined />}>Add Plan</Button>,
          <Button type="primary" onClick={handleManage}>Done</Button>,
        ]}
        destroyOnClose
      >
        <Divider style={{ marginTop: 0 }} />
        {
          loadingPLans ?
            <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
              <ProgressBar
                height="80"
                width="80"
                ariaLabel="progress-bar-loading"
                wrapperStyle={{}}
                wrapperClass="progress-bar-wrapper"
                borderColor='#F4442E'
                barColor='#51E5FF'
                visible={loadOrgPlans}
              />
            </div> :
            <>
              {
                orgPlans.length ?
                  <Space direction="vertical" style={{ width: '100%' }}>
                    {
                      orgPlans.map(plan =>
                        <Row gap={4} style={{ width: '100%' }}>
                          <Col span={4}><strong>{plan?.plan_name}</strong></Col>
                          <Col span={4}><em><CurrencyFormat value={plan?.org_plan_cost} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></em></Col>
                          <Col span={4}><em><CurrencyFormat value={plan?.org_plan_familty_cost} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></em></Col>
                          <Col span={8}><em>{plan?.plan_description}</em></Col>
                          <Col span={4}><Button type="primary" danger icon={<MinusCircleOutlined />} onClick={() => removeOrgPlan(plan)} disabled={loadingPLans || loading}>Remove</Button></Col>
                        </Row>
                      )
                    }
                  </Space> : <Empty description={<strong><em>There are no plans for this organization</em></strong>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
            </>

        }
        <Divider style={{ marginBottom: 0 }} />
      </Modal>

      <Modal title="Add A Plan" open={visisbleAddPlan} onCancel={() => setVisibleAddPlan(false)} destroyOnClose
        footer={
          [
            <Button danger onClick={() => setVisibleAddPlan(false)} disabled={loadingPLans}>Close</Button>,
            <Button type="primary" onClick={handleAddPlan} disabled={loadingPLans}>Add Plans</Button>
          ]
        }
        width={900}
      >
        <Form
          form={form}
          name="dynamic_form_complex"
          autoComplete="off"
        >
          <Form.List name="plans" >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={field.key} style={{ width: '100%' }} gap={4}>
                    <Col span={22}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                        }
                        style={{
                          width: '100%',
                        }}
                      >
                        {() => (
                          <Row gutter={[12, 2]}>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="Plan: "
                                name={[field.name, 'id']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing plan',
                                  },
                                ]}
                                style={{
                                  width: '100%',
                                }}
                              >
                                <Select
                                  onChange={handleItemSelection}
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  {plans.map((item) => (
                                    <Option key={item.plan_name} value={item.plan_id} disabled={form.getFieldsValue()?.plans?.find(itm => itm?.id === item.plan_id) || orgPlans.find(itm => itm?.plan_id === item.plan_id)}>
                                      {item.plan_name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="Unit Cost: "
                                name={[field.name, 'cost']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing plan cost',
                                  },
                                ]}
                                style={{
                                  width: '100%',
                                }}
                              >
                                <InputNumber style={{ width: '100%', }} />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...field}
                                label="Family Cost: "
                                name={[field.name, 'family_cost']}
                                rules={[
                                  {
                                    required: false,
                                  },
                                ]}
                                style={{
                                  width: '100%',
                                }}
                              >
                                <InputNumber style={{ width: '100%', }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={1}>
                      <Space align="baseline" justifyContent='end' style={{ width: '100%' }}>
                        <MinusCircleOutlined onClick={() => remove(field.name)} disabled={loadingPLans} />
                      </Space>
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} disabled={loading}>
                    Add Plan
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
}

export const HoverCard = ({ data, manageHandle, renewHandler, editHandler, statusHandler }) => {
  const [enabled, setEnabled] = useState(parseInt(data?.org_status));
  const navigate = useNavigate();

  return (
    <div className="ant-card-hoverable" style={{ width: '100%', borderRadius: '10px', overflow: 'hidden', position: 'relative' }} >
      <Card
        className="ant-card-hoverable"
        hoverable
        style={{}}
        // cover={<img alt="example" src="https://via.placeholder.com/250" />}
        bodyStyle={{ padding: '20px' }}
        onClick={() => navigate(`/corporations/users/${data?.hash}`, { state: data })}
      >
        <div className="status-button">
          <Tooltip title="Manage">
            <Button type="links" className="action-button" shape="circle" icon={enabled ? <CheckCircleOutlined /> : <CloseCircleOutlined />} style={{ color: enabled ? 'green' : 'red' }} />
          </Tooltip>
        </div>
        <Meta title={data?.org_name} description={
          <Fragment>
            <Row gutter={[2, 3]}>
              <Col span={24}>
                <Row gutter={[2, 3]}>
                  <Col span={10}>
                    Social No.:
                  </Col>
                  <Col span={14}>
                    {data?.org_social_no}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Email:
                  </Col>
                  <Col span={14}>
                    {data?.org_email || "None"}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    State / City:
                  </Col>
                  <Col span={14}>
                    {data?.org_state?.toUpperCase() || "None"} / {data?.org_city?.toUpperCase() || "None"}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Address:
                  </Col>
                  <Col span={14}>
                    {data?.org_address || "None"}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Joined On:
                  </Col>
                  <Col span={14}>
                    {(data?.org_joined_date && data?.org_joined_date?.startsWith('0')) ? 'Not Set' : (new Date(data?.org_joined_date)).toDateString()}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Last Renewal:
                  </Col>
                  <Col span={14}>
                    {(data?.org_renew_date && data?.org_renew_date?.startsWith('0')) ? 'Not Set' : (new Date(data?.org_renew_date)).toDateString()}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Next Renewal:
                  </Col>
                  <Col span={14}>
                    {(data?.org_expiry_date && data?.org_expiry_date?.startsWith('0')) ? 'Not Set' : (new Date(data?.org_expiry_date)).toDateString()}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Total Policies:
                  </Col>
                  <Col span={14}>
                    {data?.all_policies}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Active Policies:
                  </Col>
                  <Col span={14}>
                    {data?.active_policies}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Total Enrollees:
                  </Col>
                  <Col span={14}>
                    {data?.all_enrolees}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={[4, 3]}>
                  <Col span={10}>
                    Active Enrollees:
                  </Col>
                  <Col span={14}>
                    {data?.active_enrollees}
                  </Col>
                </Row>
              </Col>
              {/* <Col span={24}>
                  <Row gutter={[4,3]}>
                    <Col span={10}>
                      Address:
                    </Col>
                    <Col span={14}>
                      {data?.org_address || "None"}
                    </Col>
                  </Row>
                </Col> */}
            </Row>
          </Fragment>
        } />
      </Card>
      <div className="action-buttons">
        <Tooltip title="Manage">
          <Button className="action-button" shape="circle" icon={<SettingOutlined />} onClick={() => manageHandle(data)} />
        </Tooltip>
        <Tooltip title="Renew">
          <RenewOrgPolicy data={data} action={renewHandler} viewType="tile" />
          {/* <renewHandler data={data} viewType={'tile'} /> */}
        </Tooltip>
        <Tooltip title="Edit">
          <Button className="action-button" shape="circle" icon={<EditOutlined />} onClick={() => editHandler(data)} />
        </Tooltip>
        <Tooltip title={enabled ? "Disable" : "Enable"}>
          <Button className="action-button"
            shape="circle"
            icon={<PoweroffOutlined />}
            style={{ color: enabled ? 'red' : 'green' }}
            onClick={() => statusHandler(data)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Organizations;