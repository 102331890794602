import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  _items: [],
  _diagnosis: [],
  _careCategory: {},
  _diagnosis_final: [],
  _data: [],
};

export const paFilingManual = createSlice({
  name: "paFiling",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    _add_item: (state, action) => {
      state._items.push(action.payload.items);
      state._data.push(action.payload.data);
    },
    _add_diagnosis: (state, action) => {
      state._diagnosis = action.payload;
    },
    _add_diagnosis_final: (state, action) => {
      state._diagnosis_final = action.payload;
    },
    _add_care_category: (state, action) => {
      state._careCategory = action.payload;
    },
    _reset_items: (state, action) => {
      state._items = action.payload.items;
      state._data = action.payload.data;
    },
    _clean_items: (state, action) => {
      state._items = [];
      state._data = [];
    },
    _remove_diagnosis: (state, action) => {
      state._diagnosis = [];
    },
    _remove_care_category: (state) => {
      state._careCategory = [];
    },
    _remove_diagnosis_final: (state, action) => {
      state._diagnosis_final = [];
    },
  },
});

export const {
  _add_item,
  _remove_item,
  _reset_items,
  _add_diagnosis,
  _remove_diagnosis,
  _clean_items,
  _add_diagnosis_final,
  _remove_care_category,
  _remove_diagnosis_final,
  _add_care_category,
} = paFilingManual.actions;

export const _selectItems = (state) => state.preAuthManual._items;
export const _selectData = (state) => state.preAuthManual._data;
export const _selectDiagnosis = (state) => state.preAuthManual._diagnosis;
export const _selectCareCategory = (state) => state.preAuthManual._careCategory;
export const _selectDiagnosisFinal = (state) => state.preAuthManual._diagnosis_final;

export default paFilingManual.reducer;
