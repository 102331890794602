import React from "react";
import { Col, Divider, Row } from "antd";
import { EnrolleeConsumption } from "./EnrolleeConsumption";
import { uri_img } from "../../assets/utils/http-request";
import logo from '../../assets/images/claima.png'

export const ClientDataHook = props => {
    const {data} = props
    const style = {
      ...props?.style,
      display: 'flex',
      // flexDirection: 'column',
      // placeContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'black',
      fontSize: '1.3em',
      textAlign: 'left',
      padding: '.2rem',
      borderWidth: '.2em',
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, .4)',
      borderRadius: '.5em',
    }
  
    return(
      <div  style={style}>
        <div style={{width: 'fit-content'}} className='img-round img-medium'>
          <img
            src={data?.pic_name? `${uri_img}${data?.pic_name}` : logo}
            alt=""
            style={{width: '120px', height: '120px', display: 'flex', alignSelf: 'center', justifySelf: 'center', margin: '.3em'}}
            className="rounded-circle"
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column', width: '90%'}}>
          <Row align='middle' justify={'center'} style={{width: '100%'}}>
            <Col span={20} className='card-c-details' style={{display: 'flex', flexDirection:'column', alignSelf: 'center', justifySelf: 'center', paddingLeft: '30px'}}>
              <Row className='card-c-datarow'>
                <Col span={10} className='card-c-label'>Patient Full Name:</Col>
                <Col className='card-c-value'> {data?.surname? data?.surname.__upper() : ''}, {data?.first_name? data?.first_name : ''} {data?.middle_name? data?.middle_name : ''}</Col>
              </Row>
              <Row className='card-c-datarow'>
                <Col span={10} className='card-c-label'>Patient Insurance ID:</Col>
                <Col className='card-c-value'> {data?.insurance_no? data?.insurance_no : '' }</Col>
              </Row>
              <Row className='card-c-datarow'>
                <Col span={10} className='card-c-label'>Patient Premium Type:</Col>
                <Col className='card-c-value'> {data?.insurance_package? data?.insurance_package : ''} | {data?.premium_type? data?.premium_type : ''}</Col>
              </Row>
              <Row className='card-c-datarow'>
                <Col span={10} className='card-c-label'>Patient Premium Status:</Col>
                <Col className='card-c-value'> {(data?.status)? `Active`:'Expired'}</Col>
              </Row>
              {data?.insurance_no? <Row className='card-c-datarow'>
                <Col span={10} className='card-c-label'></Col>
                <Col className='card-c-value'> <EnrolleeConsumption userId={data?.insurance_no} enrolleeData={data}/></Col>
              </Row> : null}
            </Col>
          </Row>
          <Row justify="center"  style={{width: '100%'}}>
              <Col
                span={20}
                className="card-c-details"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-end",
                  justifySelf: "end",
                  paddingLeft: "30px",
                }}
              >
                <Divider orientation="left">Facility Of Encounter</Divider>
                <Row className="card-c-datarow">
                  <Col span={10} className="card-c-label">
                    Facility Name:
                  </Col>
                  <Col className="card-c-value">
                    {data?.facility_name?.__upper()}
                  </Col>
                </Row>
                <Row className="card-c-datarow">
                  <Col span={10} className="card-c-label">
                    Facility Email:
                  </Col>
                  <Col className="card-c-value">
                    {data?.facility_email}
                  </Col>
                </Row>
                <Row className="card-c-datarow">
                  <Col span={10} className="card-c-label">
                    Facility Supported Plan:
                  </Col>
                  <Col className="card-c-value">
                    {data?.facility_plan_type?.__capitalize()}
                  </Col>
                </Row>
                <Row className="card-c-datarow">
                  <Col span={10} className="card-c-label">
                    State / City:
                  </Col>
                  <Col className="card-c-value">
                    {data?.facility_state?.__upper()}{" "}{data?.facility_city?.__upper()}
                  </Col>
                </Row>
              </Col>
            </Row>
        </div>
      </div>
    );
  }