import React, { useState, useEffect, useRef } from "react";
import {
  Select, Button, Row, Col, Empty, Divider, Statistic,
  Card, Drawer, DatePicker,
} from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { useAuth } from "../../assets/utils/useAuth";
import "./index.css";
import { myRequest } from "../../assets/utils/lib";
import CountUp from "react-countup";
import Organizations from "../orgs";
import CheckinListHook from "../checkins";
import { ClaimsListHook, ClaimsListHookPayment } from "../claims";
import { packages, states } from "../../assets/utils/config";
import { useNavigate, useLocation } from "react-router-dom";
import Logging from "../logging";
import Loader from "../compoinents/loader";

const formatter = (value) => <CountUp end={value} separator="," />;
const { RangePicker } = DatePicker;

const PlansSummary = (props) => {
  const { user } = useAuth();
  const navigator = useNavigate();
  const { pathname } = useLocation();
  const [summary, setSummary] = useState({});
  const [filter, setFilter] = useState({});
  const [filterDate, setFilterDate] = useState({ start: null, end: null });
  const [loading, setLoading] = useState(false);
  const [loadWithFilter, setLoadWithFilter] = useState(false);
  const [filterReste, setresetFilter] = useState(false);
  const [showOrg, setShowOorg] = useState(false);
  const [showClaimsPaid, setShowClaimsPaid] = useState(false);
  const [showClaimsPendingPayment, setShowClaimsPendingPayment] =
    useState(false);

  // Statistically Related States
  const [showAllClaims, setShowAllClaims] = useState(false);
  const [showAllClaimsCFO, setShowAllClaimsCFO] = useState(false);
  const [showDeclinedClaims, setShowDeclinedClaims] = useState(false);
  const [showPendingClaims, setShowPendingClaims] = useState(false);
  const [showPaidClaims, setShowPaidClaims] = useState(false);
  const [showCheckind, setShowCheckind] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  const timerId = useRef();

  useEffect(() => {
    if (pathname !== "/dashboard") clearInterval(timerId);
  }, [pathname]);

  useEffect(() => {
    if (loadWithFilter) {
      clearInterval(timerId);
      getSummary(true);
      timerId.current = setInterval(() => {
        getSummary(false);
      }, 10000);
    }

    return () => clearInterval(timerId.current);
  }, [loadWithFilter, filter]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterReste) {
      clearInterval(timerId);
      getSummary(true);
      timerId.current = setInterval(() => {
        getSummary(false);
      }, 10000);
    }
    setresetFilter(false);
    return () => clearInterval(timerId.current);
  }, [filterReste]); //eslint-disable-line react-hooks/exhaustive-deps

  const getSummary = (first = false) => {
    if (first) setLoading(true);
    let filtered = { extended: true };

    if (loadWithFilter && Object.keys(filter).length) {
      filtered = { ...filtered, filter };

      if (filter["date"]?.length) {
        if (filter["date"][0] && filter["date"][1]) {
          filtered["filter"]["date"] = {
            start: filter["date"][0].format("YYYY-MM-DD HH:mm:ss"),
            end: filter["date"][1].format("YYYY-MM-DD HH:mm:ss"),
          };
        }
      }
    }

    myRequest("post", "plansSummary", filtered)
      .then((resp) => resp.data)
      .then((planTypes) => {
        if (planTypes && !planTypes?.error && !planTypes?.data?.length)
          setSummary(planTypes?.data);
      })
      .finally(() => {
        if (first) setLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      if (![1, 2, 3, 4, 5].includes(Number(user.role.staffRoleId))) {
        navigator("/login");
      } else {
        getSummary(true);
        timerId.current = setInterval(() => {
          getSummary(false);
        }, 5000);
      }
    } else {
      clearInterval(timerId.current);
    }
    return () => clearInterval(timerId.current);
  }, [user]); //eslint-disable-line react-hooks/exhaustive-deps

  // Finanically Related
  const showOrgDrawer = () => setShowOorg(!!!showOrg);
  const showClaimsPaidDrawer = () => setShowClaimsPaid(!!!showClaimsPaid);
  const showAClaimPendingPaymentDrawer = () =>
    setShowClaimsPendingPayment(!!!showClaimsPendingPayment);

  // Statistically Related
  const showAllClaimDrawerCFO = () => setShowAllClaimsCFO(!!!showAllClaimsCFO);
  const showAllClaimDrawer = () => setShowAllClaims(!!!showAllClaims);
  const showDeclinedClaimDrawer = () => setShowDeclinedClaims(!!!showDeclinedClaims);
  const showPendingClaimDrawer = () => setShowPendingClaims(!!!showPendingClaims);
  const showPaidClaimDrawer = () => setShowPaidClaims(!!!showPaidClaims);
  const showCheckedClients = () => setShowCheckind(!!!showCheckind);

  const showLogsDrawer = () => setShowLogs(!!!showLogs);

  const onChange = (date) => {
    setFilter((d) => ({ ...d, date: date }));
    setFilterDate({ start: date[0], end: date[1] });
  };

  const refreshSummary = () => {
    setLoadWithFilter(true);
    setresetFilter(false);
  };

  const resetFilter = () => {
    setFilter({ data: [null, null] });
    setLoadWithFilter(false);
    setresetFilter(true);
  };

  return (
    <div style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}>
      <Row gutter={[12, 12]}>
        <Col md={2} sm={2} lg={2}>
          Filters:
        </Col>
        <Col span={5} md={5} sm={8} lg={5}>
          <Select
            placeholder='State'
            options={states}
            style={{ width: "100%" }}
            value={filter?.state}
            onChange={(value) => setFilter((d) => ({ ...d, state: value }))}
          />
        </Col>
        <Col span={5} md={5} sm={8} lg={4}>
          <Select
            placeholder='Insurance Package'
            options={packages}
            style={{ width: "100%" }}
            value={filter?.package}
            onChange={(value) => setFilter((d) => ({ ...d, package: value }))}
          />
        </Col>
        <Col span={6} md={6} sm={24} lg={5}>
          <RangePicker
            picker="day"
            onChange={onChange}
            allowEmpty={[true, true]}
            style={{ width: "100%" }}
            value={[filterDate?.start, filterDate?.end]}
          />
        </Col>
        <Col span={3} md={3} sm={12} lg={4}>
          <Button
            style={{ width: "100%", placeContent: "center" }}
            onClick={refreshSummary}
            disabled={loadWithFilter}
          >
            Use Filter
          </Button>
        </Col>
        <Col span={3} md={3} sm={12} lg={4}>
          <Button
            style={{ width: "100%" }}
            onClick={resetFilter}
            disabled={!loadWithFilter}
          >
            Clear Filter
          </Button>
        </Col>
      </Row>
      <br></br>
      {loadWithFilter && (
        <Row gutter={[12, 12]} style={{ textTransform: "uppercase" }}>
          <Col
            span={3}
            style={{
              fontSize: "28",
              fontWeight: "bolder",
              display: "flex",
              alignItems: "center",
            }}
          >
            Current Filters <DoubleRightOutlined />
          </Col>
          {filter.state && (
            <>
              <Col span={3} style={{ fontSize: "25", fontWeight: "bolder" }}>
                State:
              </Col>
              <Col
                span={4}
                style={{
                  fontSize: "20",
                  fontWeight: "bolder",
                  fontStyle: "italic",
                }}
              >
                {filter.state}
              </Col>
            </>
          )}
          {filter.package && (
            <>
              <Col span={3} style={{ fontSize: "25", fontWeight: "bolder" }}>
                Package:
              </Col>
              <Col
                span={4}
                style={{
                  fontSize: "20",
                  fontWeight: "bolder",
                  fontStyle: "italic",
                }}
              >
                {filter.package}
              </Col>
            </>
          )}
          {filterDate.start && filterDate.end && (
            <>
              <Col span={3} style={{ fontSize: "25", fontWeight: "bolder" }}>
                Date Range:
              </Col>
              <Col
                span={4}
                style={{
                  fontSize: "20",
                  fontWeight: "bolder",
                  fontStyle: "italic",
                }}
              >
                {filterDate.start.format("YYYY-MM-DD")} to{" "}
                {filterDate.end.format("YYYY-MM-DD")}
              </Col>
            </>
          )}
        </Row>
      )}
      {!loading ? (
        Object.keys(summary).length ? (
          <>
            {[1, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Finances - Revenue
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  <Col md={8} sm={12} lg={8} style={{ cursor: "pointer" }}>
                    <Card
                      bordered={false}
                      onClick={showOrgDrawer}
                      hoverable
                      actions={[<>&nbsp;</>]}
                    >
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Total Reveniue</strong>}
                        value={summary?.totalPolicyCost}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={8} style={{ cursor: "alias" }}>
                    <Card
                      bordered={false}
                      actions={[<>25% of the Total Revenue</>]}
                    >
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Wakaala Fee</strong>}
                        value={parseFloat(summary?.wakaalaFee)}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={8} style={{ cursor: "alias" }}>
                    <Card
                      bordered={false}
                      actions={[<>1% of the Total Revenue</>]}
                    >
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>NHIS Fee</strong>}
                        value={parseFloat(summary?.nhisFee)}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {[1, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Finances - Expenditure
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showAllClaimDrawerCFO}>
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Total Claims</strong>}
                        value={summary?.totalClaimsCost}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showClaimsPaidDrawer}>
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Total Claims Paid</strong>}
                        value={summary?.totalClaimsPaidCost}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card
                      bordered={false}
                      onClick={showAClaimPendingPaymentDrawer}
                    >
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Pending Payment</strong>}
                        value={summary?.totalClaimsPendingCost}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showAllClaimDrawer}>
                      <Statistic
                        title={<strong>Filed Claims</strong>}
                        value={summary?.totalClaimsFiled}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showPaidClaimDrawer}>
                      <Statistic
                        title={<strong>Paid Claims</strong>}
                        value={summary?.totalPaidClaims}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showPendingClaimDrawer}>
                      <Statistic
                        title={<strong>Pending Claims</strong>}
                        value={summary?.totalPendingClaims}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showCheckedClients}>
                      <Statistic
                        title={<strong>Current Checked-ins</strong>}
                        value={summary?.activeCheckinCount}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            {/* {
                ([1,2,3,4,5].includes(Number(user?.role?.staffRoleId))) && (
                  <> */}
            <Divider
              style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
              orientationMargin={0}
              orientation="left"
            >
              {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
                <span>PA & </span>
              )}{" "}
              Activities
            </Divider>
            <Row gutter={[12, 12]} justify={"stretch"}>
              <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                <Card bordered={false} onClick={showLogsDrawer}>
                  <Statistic
                    title={<strong>Activity Count</strong>}
                    value={summary?.logs}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
                <>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Total PA Count</strong>}
                        value={summary?.total_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Pending PAs</strong>}
                        value={summary?.pending_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Approved PAs</strong>}
                        value={summary?.approved_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Rejected PAs</strong>}
                        value={summary?.rejected_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Queried PAs</strong>}
                        value={summary?.queried_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </>
              )}
            </Row>

            {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Statistics - Insurance
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Packages</strong>}
                        value={summary?.plans}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Packages Categories</strong>}
                        value={summary?.categories}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Insurance Benefits</strong>}
                        value={summary?.items}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Self Paid Clients</strong>}
                        value={summary?.selfPaidPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Corporate Clients</strong>}
                        value={summary?.corporatePolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Clients</strong>}
                        value={summary?.totalPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Enrolee</strong>}
                        value={summary?.enrolees}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Financiers</strong>}
                        value={summary?.organizations}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Statistics - Policies
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Self Paid Clients</strong>}
                        value={summary?.selfPaidPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Corporate Clients</strong>}
                        value={summary?.corporatePolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Clients</strong>}
                        value={summary?.totalPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Enrolee</strong>}
                        value={summary?.enrolees}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Financiers</strong>}
                        value={summary?.organizations}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Facilities
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  {/* <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Packages</strong>}
                        value={summary?.plans}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Packages Categories</strong>}
                        value={summary?.categories}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Insurance Benefits</strong>}
                        value={summary?.items}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Self Paid Clients</strong>}
                        value={summary?.selfPaidPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Corporate Clients</strong>}
                        value={summary?.corporatePolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Clients</strong>}
                        value={summary?.totalPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Enrolee</strong>}
                        value={summary?.enrolees}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col> */}
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Total</strong>}
                        value={summary?.facilitiesCount}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>North Central</strong>}
                        value={summary?.facilities_in_north_central}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>North East</strong>}
                        value={summary?.facilities_in_north_east}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>North West</strong>}
                        value={summary?.facilities_in_north_west}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>South East</strong>}
                        value={summary?.facilities_in_south_east}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>South South</strong>}
                        value={summary?.facilities_in_south_south}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>South West</strong>}
                        value={summary?.facilities_in_south_west}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <Empty
            description={"Summary is not available"}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      ) : (
        <Loader size={100} />
      )}
      {!loadWithFilter && (
        <Drawer
          title="Organizationwise Contribution"
          width={720}
          closable={false}
          onClose={showOrgDrawer}
          open={showOrg}
        >
          <Organizations extended={false} filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Facilitywise Distribution - Paid Claims"
          width={720}
          closable={false}
          onClose={showClaimsPaidDrawer}
          open={showClaimsPaid}
        >
          <ClaimsListHookPayment
            extended={false}
            section="paid"
            filter={filter}
          />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Facilitywise Distribution - All Claims"
          width={720}
          closable={false}
          onClose={showAllClaimDrawerCFO}
          open={showAllClaimsCFO}
        >
          <ClaimsListHookPayment
            extended={false}
            section="all"
            filter={filter}
          />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Pending Payment"
          width={720}
          closable={false}
          onClose={showAClaimPendingPaymentDrawer}
          open={showClaimsPendingPayment}
        >
          <ClaimsListHookPayment
            extended={false}
            section="pending"
            filter={filter}
          />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Declined Payment"
          width={720}
          closable={false}
          onClose={showDeclinedClaimDrawer}
          open={showDeclinedClaims}
        >
          <ClaimsListHook extended={false} section="declined" filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Filed"
          width={720}
          closable={false}
          onClose={showAllClaimDrawer}
          open={showAllClaims}
        >
          <ClaimsListHook extended={false} section="all" filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Pending QA Vetting"
          width={720}
          closable={false}
          onClose={showPendingClaimDrawer}
          open={showPendingClaims}
        >
          <ClaimsListHook extended={false} section="pending" filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Paid"
          width={720}
          closable={false}
          onClose={showPaidClaimDrawer}
          open={showPaidClaims}
        >
          <ClaimsListHook extended={false} section="paid" filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Check-In Clients"
          width={720}
          closable={false}
          onClose={showCheckedClients}
          open={showCheckind}
        >
          <CheckinListHook extended={false} filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Activity Logs"
          width={720}
          closable={true}
          onClose={showLogsDrawer}
          open={showLogs}
          destroyOnClose
        >
          <Logging extended={false} filter={filter} />
        </Drawer>
      )}
    </div>
  );
};

const Dashboard = () => {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <PlansSummary />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
