import React, { useState } from "react";
import { Button, Col, DatePicker, Modal, Row, notification } from "antd";
import { CheckCircleFilled, ClockCircleFilled, LoadingOutlined, SyncOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import lib, { confirmModal, myRequestObj, myTime } from "../../assets/utils/lib";

export const RenewPolicy = ({ data, action }) => {
  const [showPolicyUpdate, setShowPolicyUpdate] = useState(false);
  const [updateData, setUpdateData] = useState({ expiry_date: data?.expiry_date, id: data?.policy_no });

  const idCard = () => {
    setShowPolicyUpdate(!showPolicyUpdate)
  }

  const handleRenewDate = (obj, value) => {
    const timing = value.split(': ')[1];
    setUpdateData(e => ({ ...e, expiry_date: timing }))
  }

  const handleRenew = async () => {
    const key = 'up';

    if (data?.expiry_date?.split(' ')[0] !== updateData?.expiry_date && data?.expiry_date !== null && updateData?.expiry_date !== null) {
      const myCurrentTime = await myTime()
      const today = new Date(myCurrentTime.utc_datetime)
      const expiry = new Date(updateData?.expiry_date)
      const content = <>
        <p>Are you sure you want to update client's premium expiry?</p>
        <p>
          <span><strong>Current Expiry Date: </strong>{data?.expiry_date?.split(' ')[0]}</span><br></br>
          <span><strong>Proposed Expiry Date: </strong>{updateData?.expiry_date}</span><br></br><br></br>
          {today > expiry && <span style={{ color: 'red' }}><strong>Note: </strong> This operation will disable this disable the policy and the associated enrollees</span>}
        </p>
      </>
      const type = today > expiry ? 'error' : 'warning';
      confirmModal(content, type).then(result => {
        if (!result) return;

        notification.info({
          message: "Premium Update", description: <>
            Updating Premium Expiry for <strong>{data?.policy_no}</strong> from <strong>{data?.expiry_date?.split(' ')[0]}</strong> To <strong>{updateData?.expiry_date}</strong></>,
          placement: 'topLeft',
          key,
          duration: 0,
          icon: <LoadingOutlined />
        });

        myRequestObj('updatePolicyExpiry').post(updateData)
          .then(result => {
            if (result?.status === 'error') return;
            if (Number(result?.data?.error) === 0) {
              notification.success({
                message: <>
                  {result?.data?.message} <br></br><br></br>
                  <strong>Note: </strong> The update will be visible until next reload,
                  you can do that by either <strong>closing the popups and click the search button again</strong> or <strong>CTR + R</strong> to reload the page.
                </>,
                icon: <CheckCircleFilled color="green" />,
                key,
                duration: 0,
                placement: 'topLeft',
              })
              idCard();
            } else {
              notification.error({
                message: result?.data?.message,
                icon: <ClockCircleFilled color="red" />,
                key,
                duration: 0,
                placement: 'topLeft',
              })
            }
            console.log(result?.data)
          }).finally(() => {
            setTimeout(() => notification.destroy(key), 5000)
          })
      })
    }
  };

  const dateFormat = 'YYYY-MM-DD';
  const customFormat = (value) => `Active Till: ${value.format(dateFormat)}`;

  return (
    <>
      <Button  onClick={idCard} >Extend Expiration</Button>
      <Modal
        title="Premium Expiry Date Update"
        open={showPolicyUpdate}
        onOk={handleRenew}
        onCancel={idCard}
        cancelText='Close'
        forceRender
        closable
        destroyOnClose
      >
        <br></br>
        <Row gap={6} style={{ margin: '10px 10px' }}>
          <Col span={8}>Desired Extension</Col>
          <Col span={16}>
            <DatePicker defaultValue={dayjs(data?.expiry_date, dateFormat)} format={customFormat} onChange={handleRenewDate} />
          </Col>
        </Row>
        <br></br>
      </Modal>
    </>
  )
}

export const RenewOrgPolicy = ({ data, action, viewType = 'tile' }) => {
  const [showPolicyUpdate, setShowPolicyUpdate] = useState(false);
  const [updateData, setUpdateData] = useState({ validity_period: data?.org_expiry_date, org_social_no: data?.org_social_no });
  const [view, setView] = useState(viewType)

  const idCard = () => {
    setShowPolicyUpdate(!showPolicyUpdate)
  }

  const handleRenewDate = (obj, value) => {
    const timing = value.split(': ')[1];
    setUpdateData(e => ({ ...e, validity_period: timing }))
  }

  const handleRenew = async () => {
    const key = 'up';

    if (data?.org_expiry_date !== updateData?.validity_period && updateData?.validity_period !== null) {
      const myCurrentTime = await myTime()
      const today = new Date(myCurrentTime.utc_datetime)
      const expiry = new Date(updateData?.validity_period)
      const content = <>
        <p>Are you sure you want to update org's premium expiry?</p>
        <p>
          <span><strong>Current Expiry Date: </strong>{data?.org_expiry_date?.split(' ')[0]}</span><br></br>
          <span><strong>Proposed Expiry Date: </strong>{updateData?.validity_period}</span><br></br><br></br>
          {today > expiry && <span style={{ color: 'red' }}><strong>Note: </strong>
            This operation will disable this disable all policy and associated enrollees associated to this organization</span>}
        </p>
      </>
      const type = 'warning';
      confirmModal(content, type).then(result => {
        if (!result) return;

        notification.info({
          message: "Premium Update", description: <>
            Updating Premium Expiry for <strong>{data?.org_name}</strong> from <strong>{data?.org_expiry_date?.split(' ')[0]}</strong> To <strong>{updateData?.validity_period}</strong></>,
          placement: 'topLeft',
          key,
          duration: 0,
          icon: <LoadingOutlined />
        });

        lib.updateOrgDate(updateData)
          .then(result => {
            if (result?.status === 'error') return;
            if (Number(result?.data?.error) === 0) {
              notification.success({
                message: <>
                  {result?.data?.message} <br></br><br></br>
                  <strong>Note: </strong> The update will be visible until next reload,
                  you can do that by either <strong>closing the popups and click the search button again</strong> or <strong>CTR + R</strong> to reload the page.
                </>,
                icon: <CheckCircleFilled color="green" />,
                key,
                duration: 0,
                placement: 'topLeft',
              })
              idCard();
              // action();
            } else {
              notification.error({
                message: result?.data?.message,
                icon: <ClockCircleFilled color="red" />,
                key,
                duration: 0,
                placement: 'topLeft',
              })
            }
            // console.log(result?.data)
          }).finally(() => {
            setTimeout(() => notification.destroy(key), 5000)
          })
      })
    }
  };

  const dateFormat = 'YYYY-MM-DD';
  const customFormat = (value) => `Active Till: ${value.format(dateFormat)}`;

  return (
    <>

      {view !== 'tile' ?
        <Button type="dashed" onClick={idCard} >Update Validity</Button> :
        <Button className="action-button" shape="circle" icon={<SyncOutlined />} onClick={idCard} />
      }
      <Modal
        title="Organizational Premium Validity Update"
        open={showPolicyUpdate}
        onOk={handleRenew}
        onCancel={idCard}
        forceRender
        closable
        destroyOnClose
      >
        <br></br>
        <Row gap={6} style={{ margin: '10px 10px' }}>
          <Col span={24}>
            <Row gutter={[4, 3]}>
              <Col span={10}>
                Name:
              </Col>
              <Col span={14}>
                {data?.org_name}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[2, 3]}>
              <Col span={10}>
                Social No.:
              </Col>
              <Col span={14}>
                {data?.org_social_no}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[4, 3]}>
              <Col span={10}>
                Next Renewal:
              </Col>
              <Col span={14}>
                {(data?.org_expiry_date && data?.org_expiry_date?.startsWith('0')) ? 'Not Set' : (new Date(data?.org_expiry_date)).toDateString()}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row gutter={[4, 3]}>
              <Col span={10}>Desired Extension</Col>
              <Col span={14}>
                <DatePicker defaultValue={dayjs(dayjs(), dateFormat)} format={customFormat} onChange={handleRenewDate} />
              </Col>
            </Row>
          </Col>
        </Row>
        <br></br>
      </Modal>
      <style jsx>
        {
          `
            .action-button {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          `
        }
      </style>
    </>
  )
}