import React, { useState, useEffect } from "react";
import * as antd from "antd";
import "./index.css";
import {
  fetchPaginatedData,
  findDifferentKeysTwoObj,
  myRequest,
  omitKeys,
  removeDuplicates,
  underscoreToCapitalizedWords,
} from "../../assets/utils/lib";
import { ReloadOutlined } from "@ant-design/icons";
import { Dna } from "react-loader-spinner";
import CurrencyFormat from "react-currency-format";
import { useAuth } from "../../assets/utils/useAuth";

const claim_statuses = (status) => {
  switch (parseInt(status)) {
    case 0:
      return <antd.Tag color="default">Pending Claims Clearance</antd.Tag>;
    case 1:
      return <antd.Tag color="warning">Pending Medical Clearance</antd.Tag>;
    case 2:
      return <antd.Tag color="processing">Pending Payment</antd.Tag>;
    case 3:
      return <antd.Tag color="success">Paid</antd.Tag>;
    default:
  }
};

const claim_items_statuses = (status) => {
  switch (status) {
    case 0:
      return <antd.Tag color="default">Pending</antd.Tag>;
    case 1:
      return <antd.Tag color="success">Approved</antd.Tag>;
    case 2:
      return <antd.Tag color="warning">Queried</antd.Tag>;
    case 3:
      return <antd.Tag color="error">Rejected</antd.Tag>;
    default:
      return status;
  }
};

const Log = () => {

  const [, setFiledItemsLoading] = useState(false);

  const [allDataPulled] = useState(false);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    if (!allDataPulled) {
      fetLog();
    }
  }, [allDataPulled]);

  const [n_data, set_N_Data] = useState([]);

  const fetLog = async () => {
    const daa = [];
    try {
      for await (const data of fetchPaginatedData("post", "log", {})) {
        set_N_Data((d) => [...data, ...d]);
        daa.push(...data.map((d) => ({ ...d, key: d.logs_id })));
      }
      set_N_Data(daa);
      setFiledItemsLoading(false);
    } catch (e) {
      antd.notification.error({ message: "Network Error" });
    }
  };

  const expandedRowRender = (record) => {
    // This function defines what content to display when a row is expanded
    return (
      <div>
        {/* Content for the expanded row */}
        <p>{record.logs_message}</p>
        <antd.Divider>Affected Record</antd.Divider>
        {record.targetData !== null && (
          <>
            {record.targetData._type === "clients" && (
              <>
                <p>
                  <strong>Name</strong>: {record.targetData?.name}
                </p>
                <p>
                  <strong>Policy Number</strong>: {record.targetData?.policy_no}
                </p>
                <p>
                  <strong>Policy Type</strong>:{" "}
                  {record.targetData?.premium_type}
                </p>
                <p>
                  <strong>Policy Category</strong>:{" "}
                  {record.targetData?.category}
                </p>
                <p>
                  <strong>Insurance Package</strong>:{" "}
                  {record.targetData?.insurance_package}
                </p>
              </>
            )}

            {record.targetData?._type === "client_biodata" && (
              <>
                <p>
                  <strong>Full Name</strong>: {record.targetData?.first_name}{" "}
                  {record.targetData?.middle_name} {record.targetData?.surname}
                </p>
                <p>
                  <strong>Policy Number</strong>: {record.targetData?.policy_no}
                </p>
                <p>
                  <strong>Insurance Number</strong>:{" "}
                  {record.targetData?.insurance_no}
                </p>
                <p>
                  <strong>Role</strong>: {record.targetData?.role}
                </p>
                <p>
                  <strong>State</strong>: {record.targetData?.state}
                </p>
                <p>
                  <strong>LGA</strong>: {record.targetData?.lga}
                </p>
              </>
            )}

            {record.targetData?._type === "staff" && (
              <>
                <p>
                  <strong>Full Name</strong>: {record.targetData?.firstName}{" "}
                  {record.targetData?.middleName}{" "}
                  {record.targetData?.firstName &&
                    record.targetData?.Surname &&
                    ","}{" "}
                  {record.targetData?.Surname?.toUpperCase()}
                </p>
                <p>
                  <strong>Staff Id</strong>: {record.targetData?.policy_no}
                </p>
                <p>
                  <strong>Phone</strong>: {record.targetData?.phone}
                </p>
                <p>
                  <strong>Staff Role</strong>:{" "}
                  {record.targetData?.staffRoleName}
                </p>
                <p>
                  <strong>State</strong>: {record.targetData?.state}
                </p>
                <p>
                  <strong>City</strong>: {record.targetData?.city}
                </p>
              </>
            )}

            {record.targetData?._type === "claim_items" && (
              <>
                <p>
                  <strong>Checkin ID</strong>: {record.targetData?.checkin_id}
                </p>
                <p>
                  <strong>Item ID</strong>: {record.targetData?.item_id}
                </p>
                <p>
                  <strong>Quantity</strong>: {record.targetData?.quantity}
                </p>
                <p>
                  <strong>Cost</strong>: {record.targetData?.cost}
                </p>
                <p>
                  <strong>Approved Cost</strong>:{" "}
                  {record.targetData?.approved_cost}
                </p>
                <p>
                  <strong>Authorization Ccode</strong>:{" "}
                  {record.targetData?.auth_code}
                </p>
              </>
            )}

            {record.targetData?._type === "claims" && (
              <>
                <p>
                  <strong>Claim ID</strong>: {record.targetData?.claims_id}
                </p>
                <p>
                  <strong>Checkin ID</strong>: {record.targetData?.checkin_id}
                </p>
                <p>
                  <strong>Medical Compalaint</strong>:{" "}
                  {record.targetData?.medical_complaint_hm}
                </p>
                <p>
                  <strong>Claim Status</strong>:{" "}
                  {claim_statuses(record.targetData?.claims_status)}
                </p>
              </>
            )}

            {record.targetData?._type === "checkin" && (
              <>
                <p>Details not available</p>
              </>
            )}

            {record.targetData?._type === "clients" && (
              <>
                <p>Details not available</p>
              </>
            )}
          </>
        )}

        {record.targetData === null && (
          <>
            <p>Details not available</p>
          </>
        )}
      </div>
    );
  };

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.key]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
    }
  };

  const columns = [
    {
      title: "Action",
      dataIndex: "app_action_name",
      key: "app_action_name",
      width: "50%",
    },
    {
      title: "Date",
      dataIndex: "logs_date",
      key: "logs_date",
      width: "50%",
    },
  ];

  return (
    <div style={{ width: "100%", margin: "20px 0 5px 0", padding: 0 }}>
      <antd.Space
        direction="vertical"
        justify={"space-between"}
        style={{ width: "100%" }}
      >
        <antd.Table
          columns={columns}
          dataSource={removeDuplicates(n_data, "logs_id")}
          expandable={{ expandedRowRender, onExpand, expandedRowKeys }}
          rowKey={"logs_id"}
        />
      </antd.Space>
    </div>
  );
};

export const Logs = () => {
  const {user} = useAuth();

  const [loading, setLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);

  const [actionFilterLoading, ] = useState(false);
  const [depaertmentFilterLoading, ] = useState(false);
  const [applicationFilterLoading, ] = useState(false);
  const [keywardFilterLoading, ] = useState(false);
  const [allDataPulled] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [realod, setReload] = useState(false);
  const [allPages, setAllPages] = useState(0);
  const [nextPage, setNextPage] = useState(1);

  const [actionFilterValue, setActionFilterValue] = useState();
  const [depaertmentFilterValue, setDepaertmentFilterValue] = useState();
  const [applicationFilterValue, setApplicationFilterValue] = useState();
  const [keywardFilterValue, setKeywardFilterValue] = useState();

  useEffect(() => {
    if (!allDataPulled) {
      fetLog({reload: false, initialLoad: true, payload: {page: nextPage} });
      loadActivities();
      loadModules();
      loadDepartments();
    }
  }, [allDataPulled]); //eslint-disable-line react-hooks/exhaustive-deps

  const [n_data, set_N_Data] = useState([]);
  const [actions, setActions] = useState([]);
  const [modules, setModules] = useState([]);
  const [departments, setDepartments] = useState([]);

  const fetLog = async ({reload = false, initialLoad = false, payload = {}} = {}) => {
    try {
      ( initialLoad || reload ) && (setLoading(true));
      setGeneralLoading(true)
      myRequest("post", "logs", payload)
        .then((resp) => {
          if (Number(resp?.data?.error) === 0) {
            const d_data = resp?.data?.data;

            setAllPages(Number(d_data?.pages));
            
            if(d_data?.warning && d_data?.warning !== '') {
              antd.notification.info({message: d_data?.warning})
            }

            if (reload || initialLoad) {
              set_N_Data(removeDuplicates(d_data?.data, 'logs_id'));
            } else {
              d_data?.data && set_N_Data((d) => removeDuplicates([...d, ...d_data?.data], 'logs_id'))
            }

            setInitialLoad(false);
          }
        })
        .finally((e) => {
          setGeneralLoading(false);
          setLoading(false)
          setReload(false)
        })
    } catch (e) {
      setLoading(false);
      antd.notification.error({ message: "Network Error" });
    }
  };

  const loadActivities = () => {
    try {
      // setLoading(true);
      myRequest("post", "app_activities", {}).then((resp) => {
        if (!parseInt(resp?.data?.error)) {
          setActions(resp?.data?.data);
        }
      });
    } catch (e) {
      antd.notification.error({ message: "Network Error" });
    }
  };

  const loadModules = () => {
    try {
      // setLoading(true);
      myRequest("post", "app_modules", {}).then((resp) => {
        if (!parseInt(resp?.data?.error)) {
          setModules(resp?.data?.data);
        }
      });
    } catch (e) {
      antd.notification.error({ message: "Network Error" });
    }
  };

  const loadDepartments = () => {
    try {
      setLoading(true);
      myRequest("post", "app_departments", {}).then((resp) => {
        if (!parseInt(resp?.data?.error)) {
          setDepartments(resp?.data?.data);
        }
      });
    } catch (e) {
      antd.notification.error({ message: "Network Error" });
    }
  };

  const actionFilter = e => {
    setActionFilterValue(e)
    const payload = {actionType: e}
    if(keywardFilterValue) payload.filterKeyword = keywardFilterValue
    if(depaertmentFilterValue) payload.departmentType = depaertmentFilterValue
    if(applicationFilterValue) payload.applicationType = applicationFilterValue
    
    fetLog({reload: true, payload });
  }

  const departmentFilter = e => {
    setDepaertmentFilterValue(e)

    const payload = {departmentType: e}
    if(actionFilterValue) payload.actionType = actionFilterValue
    if(keywardFilterValue) payload.filterKeyword = keywardFilterValue
    if(applicationFilterValue) payload.applicationType = applicationFilterValue

    fetLog({reload: true, payload});
  }

  const applicationFilter = e => {
    setApplicationFilterValue(e)

    const payload = {applicationType: e}
    if(actionFilterValue) payload.actionType = actionFilterValue
    if(keywardFilterValue) payload.filterKeyword = keywardFilterValue
    if(depaertmentFilterValue) payload.departmentType = depaertmentFilterValue

    fetLog({reload: true, payload });
  }

  const keywardFilter = e => {
    setKeywardFilterValue(e)
    
    const payload = {filterKeyword: e}
    if(actionFilterValue) payload.actionType = actionFilterValue
    if(depaertmentFilterValue) payload.departmentType = depaertmentFilterValue
    if(applicationFilterValue) payload.applicationType = applicationFilterValue

    fetLog({reload: true, payload });
  }

  const floatButtonAction = () => {
    if(!(generalLoading || loading)){
      setActionFilterValue();
      setDepaertmentFilterValue();
      setApplicationFilterValue();
      setKeywardFilterValue();
      fetLog({reload: true}); 
    }
  }

  const loadMore = e => {
    if (allPages - nextPage >= 0) {
      fetLog({ payload: {page: nextPage} });
      setNextPage(nextPage + 1);
    }
  }

  return (
    <>
      <antd.FloatButton
        onClick={floatButtonAction}
        icon={<ReloadOutlined spin={loading} />}
      />
      <antd.Space.Compact
        direction="horinzotal"
        style={{ width: "100%", marginTop: 20 }}
      >
        <antd.Space.Compact style={{ width: "100%" }}>
          <p style={{ display: "inline-block", paddingRight: 10 }}>
            <strong>Filter By Fields: </strong>
          </p>
          <antd.Select
            placeholder="Action"
            style={{ width: 250 }}
            onChange={actionFilter}
            options={actions}
            value={actionFilterValue}
            loading={actionFilterLoading}
            disabled={keywardFilterLoading || applicationFilterLoading || depaertmentFilterLoading || actionFilterLoading || generalLoading}
          />
          <antd.Select
            placeholder="Department"
            style={{ width: 250 }}
            onChange={departmentFilter}
            options={departments}
            value={depaertmentFilterValue}
            loading={depaertmentFilterLoading}
            disabled={keywardFilterLoading || applicationFilterLoading || depaertmentFilterLoading || actionFilterLoading || generalLoading}
          />
          { (user?.role?.staffRoleId && [1].includes(Number(user?.role?.staffRoleId))) && 
            <antd.Select
              placeholder="Application"
              style={{ width: 250 }}
              onChange={applicationFilter}
              options={modules}
              value={applicationFilterValue}
              loading={applicationFilterLoading}
              disabled={keywardFilterLoading || applicationFilterLoading || depaertmentFilterLoading || actionFilterLoading || generalLoading}
            />
          }
        </antd.Space.Compact>
        <antd.Space.Compact
          direction="horizontal"
          style={{ width: "40%" }}
        >
          <p
            style={{
              padding: "0 10px",
              margin: 0,
              width: "fit-content",
            }}
          >
            <strong>Filter By Keyward:</strong>
          </p>
          <antd.Input.Search
            style={{ width: 200 }}
            placeholder="Search Keyward"
            loading={keywardFilterLoading}
            disabled={keywardFilterLoading || applicationFilterLoading || depaertmentFilterLoading || actionFilterLoading || generalLoading}
            onSearch={keywardFilter}
            value={keywardFilterValue}
          />
        </antd.Space.Compact>
      </antd.Space.Compact>
      <antd.Divider style={{marginBottom: !n_data?.length && 100}}/>
      {(initialLoad ||  realod || loading )? (
        <>
          <Dna
            visible={true}
            height="100"
            width="100"
            ariaLabel="dna-loading"
            wrapperStyle={{ position: "relative", top: "40%", left: "40%" }}
            wrapperClass="dna-wrapper"
          />
        </>
      ) : (
        <>
          {!n_data?.length ? (
            <>
              <antd.Empty
                description="There are no activities"
                image={antd.Empty.PRESENTED_IMAGE_DEFAULT}
              />
            </>
          ) : (
            <div style={{ width: "100%", margin: "20px 0 5px 0", padding: 0 }}>
              <antd.Space
                direction="vertical"
                justify={"space-between"}
                style={{ width: "100%" }}
              >
                {n_data.map((item) => (
                  <LogsCard data={item} key={item["logs_id"]} />
                ))}
              </antd.Space>
              <antd.Row justify={"end"}>
                <antd.Col>
                  <antd.Button
                    type="primary"
                    onClick={loadMore}
                    disabled={allPages - nextPage <= 0 || loading  || generalLoading}
                    loading={loading || generalLoading}
                  >
                    Load More
                  </antd.Button>
                </antd.Col>
              </antd.Row>
            </div>
          )}
        </>
      )}
    </>
  );
};

const LogsCard = ({ data, key }) => {
  return (
    <>
      <DataRow data={data} _type={data?.targetData?._type} key={key} />
    </>
  );
};

const DataRow = ({ style, data, _type }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [, setExtendedData] = useState({});

  useEffect(() => {
    if (viewDetails) fetchDetails(data);
  }, [viewDetails, data]);

  const fetchDetails = (logData) => {
    console.log(logData);
    setExtendedData({});
  };

  const shoeDetail = () => {
    setViewDetails(!viewDetails);
    console.log(data);
  };
  
  return (
    <>
      <antd.Row
        style={{
          padding: "10px",
          borderBottom: "1px solid black",
          borderTop: "1px solid black",
          margin: "10px 0",
          cursor: "pointer",
          ...style,
        }}
      >
        {data?.log_type_table === "staff" && <StaffActor data={data} action={shoeDetail} viewDetails={viewDetails} />}
        {data?.log_type_table === "clients" && <ClientActor data={data} action={shoeDetail} viewDetails={viewDetails} />}
        {data?.log_type_table === "clients" && <FacilityActor data={data} action={shoeDetail} viewDetails={viewDetails} />}
        {_type === "clients" && <AffectedClient data={data} type={viewDetails} />}
        {_type === "client_biodata" && <AffectedEnrolee data={data} type={viewDetails} />}
        {_type === "claim_items" && <AffectedClaimItem data={data} type={viewDetails} />}
        {_type === "staff" && <AffectedStaff data={data} type={viewDetails} />}
        {_type === "claims" && <AffectedClaim data={data} type={viewDetails} />}
        {_type === "checkin" && <AffectedCheckin data={data} type={viewDetails} />}
      </antd.Row>
    </>
  );
};


// The Actors
const StaffActor = ({style, data, action, viewDetails}) => {
  console.log(data);
  return <>
    <antd.Col span={24}>
      <antd.Row align={"middle"}>
        <antd.Col span={3}>
          <antd.Row>
            <antd.Col span={24}>Staff Id: </antd.Col>
            <antd.Col span={24}>{data?.staff?.staffId}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={8}>
          <antd.Row>
            <antd.Col span={24}>Staff Name: </antd.Col>
            <antd.Col span={24}>
              {data?.staff?.biodata?.firstName}{" "}
              {data?.staff?.biodata?.middleName}{" "}
              {data.targetData?.Surname && ","}{" "}
              {data?.staff?.biodata?.Surname}
            </antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={6}>
          <antd.Row>
            <antd.Col span={24}>Department: </antd.Col>
            <antd.Col span={24}>{data?.staff?.department?.name}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={4}>
          <antd.Row>
            <antd.Col span={24}>Date: </antd.Col>
            <antd.Col span={24}>{data?.logs_date}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={3}>
          <antd.Row>
            <antd.Col span={24}>
              <antd.Button onClick={action}>
                {viewDetails ? "Hide" : "View"} Details
              </antd.Button>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Col span={24}>
                <antd.Tag color="processing">
                  {data?.targetData?._type
                    .split("_")
                    .join(" ")
                    .toUpperCase()}{" "}
                  {data?.app_action_name?.toUpperCase()}
                </antd.Tag>
              </antd.Col>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      </antd.Row>
    </antd.Col>
  </>
}

const ClientActor = ({style, data, action, viewDetails}) => {

  return <>
    <antd.Col span={24}>
      <antd.Row align={"middle"}>
        <antd.Col span={8}>
          <antd.Row>
            <antd.Col span={24}>Family Name: </antd.Col>
            <antd.Col span={24}>
              {data?.client?.name}
            </antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={3}>
          <antd.Row>
            <antd.Col span={24}>Policy No.: </antd.Col>
            <antd.Col span={24}>{data?.client?.policy_no}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={6}>
          <antd.Row>
            <antd.Col span={24}>Phone: </antd.Col>
            <antd.Col span={24}>{data?.client?.phone}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={4}>
          <antd.Row>
            <antd.Col span={24}>Date: </antd.Col>
            <antd.Col span={24}>{data?.logs_date}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={3}>
          <antd.Row>
            <antd.Col span={24}>
              <antd.Button onClick={action}>
                {viewDetails ? "Hide" : "View"} Details
              </antd.Button>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Col span={24}>
                <antd.Tag color="processing">
                  {data?.targetData?._type
                    .split("_")
                    .join(" ")
                    .toUpperCase()}{" "}
                  {data?.app_action_name?.toUpperCase()}
                </antd.Tag>
              </antd.Col>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      </antd.Row>
    </antd.Col>
  </>
}

const FacilityActor = ({style, data, action, viewDetails}) => {

  return <>
    <antd.Col span={24}>
      <antd.Row align={"middle"}>
        <antd.Col span={8}>
          <antd.Row>
            <antd.Col span={24}>Family Name: </antd.Col>
            <antd.Col span={24}>
              {data?.client?.name}
            </antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={3}>
          <antd.Row>
            <antd.Col span={24}>Policy No.: </antd.Col>
            <antd.Col span={24}>{data?.client?.policy_no}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={6}>
          <antd.Row>
            <antd.Col span={24}>Phone: </antd.Col>
            <antd.Col span={24}>{data?.client?.phone}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={4}>
          <antd.Row>
            <antd.Col span={24}>Date: </antd.Col>
            <antd.Col span={24}>{data?.logs_date}</antd.Col>
          </antd.Row>
        </antd.Col>
        <antd.Col span={3}>
          <antd.Row>
            <antd.Col span={24}>
              <antd.Button onClick={action}>
                {viewDetails ? "Hide" : "View"} Details
              </antd.Button>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Col span={24}>
                <antd.Tag color="processing">
                  {data?.targetData?._type
                    .split("_")
                    .join(" ")
                    .toUpperCase()}{" "}
                  {data?.app_action_name?.toUpperCase()}
                </antd.Tag>
              </antd.Col>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      </antd.Row>
    </antd.Col>
  </>
}

// Target Handler
const AffectedClient = ({ data, type }) => {
  const oldTargetData = data["logs_target_old_data"]
    ? JSON.parse(data["logs_target_old_data"])
    : {};
  const newTargetData = data["logs_target_new_data"]
    ? JSON.parse(data["logs_target_new_data"])
    : {};
  const targetData = data?.targetData ? data.targetData : {};

  const keysToOmit = [
    "association_id",
    "plans_id",
    "payment_id",
    "id",
    "password",
    "disclaimer",
    "package_size",
    "additional_heads",
    "_type",
  ];

  const changedKeys = findDifferentKeysTwoObj(
    oldTargetData,
    newTargetData,
    keysToOmit
  );

  return (
    <>
      <antd.Divider
        orientation={type ? "center" : "left"}
        orientationMargin={0}
      >
        Affected Data - Policy Account - {type ? "Detailed" : "Brief"}
      </antd.Divider>
      {!type ? (
        <antd.Col span={24}>
          <antd.Row align={"middle"}>
            <antd.Col span={6}>
              <antd.Row>
                <antd.Col span={24}>Name: </antd.Col>
                <antd.Col span={24}>
                  {data?.targetData?.name?.toUpperCase()}
                </antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Row>
                <antd.Col span={24}>Policy Number: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.policy_no}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Policy Type: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.premium_type}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Policy Category: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.category}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Row>
                <antd.Col span={24}>Insurance Package: </antd.Col>
                <antd.Col span={24}>
                  {data?.targetData?.insurance_package}
                </antd.Col>
              </antd.Row>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      ) : (
        <>
          <AffectedClientDetail
            title={"Current Data"}
            data={omitKeys(targetData, keysToOmit)}
            changedKeys={[]}
          />
          <antd.Divider type="vertical" />
          <AffectedClientDetail
            title={"Pre-action Data"}
            data={omitKeys(oldTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
          <antd.Divider type="vertical" />
          <AffectedClientDetail
            title={"Post-action Data"}
            data={omitKeys(newTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
        </>
      )}
    </>
  );
};

const AffectedClientDetail = ({ data, title, changedKeys }) => {
  const theTruthyManipulator = (obj) => {
    if (obj === undefined || obj === null) return {};

    const truthyValues = [
      "status",
      "paymentStatus",
      "flagged",
      "update_required",
      "updated",
    ];

    const keys = Object.keys(obj);
    let objCopy = {};

    for (let k of keys) {
      if (truthyValues.includes(k)) {
        if (k === "date") {
          objCopy["registrastion_date"] = obj[k];
          continue;
        }
      }

      objCopy[k] = obj[k];
    }

    return objCopy;
  };

  return (
    <>
      {/* <antd.Divider type="vertical" /> */}
      <div style={{ width: "32%" }}>
        <antd.Divider
          type="horizontal"
          orientation="left"
          orientationMargin={0}
        >
          {title}
        </antd.Divider>
        <antd.Row
          align={"middle"}
          style={{ boxShadow: "2px 3px 5px rgba(0, 0, 0, .2)", padding: 10 }}
        >
          {Object.entries(theTruthyManipulator(data)).map((v) => (
            <antd.Col
              span={24}
              style={{
                color: changedKeys.includes(v[0]) ? "white" : "",
                backgroundColor: changedKeys.includes(v[0]) ? "red" : "",
              }}
            >
              <antd.Row>
                <antd.Col span={12}>
                  {underscoreToCapitalizedWords(v[0]).toUpperCase()}:{" "}
                </antd.Col>
                <antd.Col span={12}>
                  {/* */}
                  {v[0] === "status" && 
                    <antd.Tag color={Number(v[1]) === 1 ? "success" : "error"}>
                      {Number(v[1]) === 1 ? "Active" : "Inactive"}
                    </antd.Tag>
                  }
                  {v[0] === "flagged" && 
                    <antd.Tag color={Number(v[1]) === 1 ? "error" : "success"}>
                      {Number(v[1]) === 1 ? "Flagged" : "Not Flagged"}
                    </antd.Tag>
                  }
                  {v[0] === "paymentStatus" && 
                    <antd.Tag color={Number(v[1]) === 1 ? "success" : "warning"}>
                      {Number(v[1]) === 1 ? "Paid" : "Not paid"}
                    </antd.Tag>
                  }
                  {v[0] === "update_required" && 
                    <antd.Tag color={Number(v[1]) === 1 ? "error" : "processing"}>
                      {Number(v[1]) === 1 ? "Yes" : "No"}
                    </antd.Tag>
                  }
                  {v[0] === "updated" && 
                    <antd.Tag color={Number(v[1]) === 1 ? "success" : "error"}>
                      {Number(v[1]) === 1 ? "Updated" : "No Updated"}
                    </antd.Tag>
                  }
                  {!(
                    v[0] === "status" ||
                    v[0] === "flagged" ||
                    v[0] === "update_required" ||
                    v[0] === "updated" ||
                    v[0] === "paymentStatus"
                  ) &&  v[1]}
                </antd.Col>
              </antd.Row>
            </antd.Col>
          ))}
        </antd.Row>
      </div>
    </>
  );
};

const AffectedEnrolee = ({ data, type }) => {
  const oldTargetData = data["logs_target_old_data"]
    ? JSON.parse(data["logs_target_old_data"])
    : {};
  const newTargetData = data["logs_target_new_data"]
    ? JSON.parse(data["logs_target_new_data"])
    : {};

  const targetData = data?.targetData ? data.targetData : {};

  const keysToOmit = ["financier_id", "id", "id_name", "pic_name", "_type"];

  const changedKeys = findDifferentKeysTwoObj(
    oldTargetData,
    newTargetData,
    keysToOmit
  );

  return (
    <>
      <antd.Divider
        orientation={type ? "center" : "left"}
        orientationMargin={0}
      >
        Affected Data - Enrolee - {type ? "Detailed" : "Brief"}
      </antd.Divider>
      {!type ? (
        <antd.Col span={24}>
          <antd.Row align={"middle"}>
            <antd.Col span={6}>
              <antd.Row>
                <antd.Col span={24}>Full Name: </antd.Col>
                <antd.Col span={24}>
                  {data.targetData?.first_name?.toUpperCase()}{" "}
                  {data.targetData?.middle_name?.toUpperCase()}{" "}
                  {data.targetData?.surname?.toUpperCase()}
                </antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Insurance Number: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.insurance_no}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Row>
                <antd.Col span={24}>Role: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.role}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Row>
                <antd.Col span={24}>State: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.state}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Row>
                <antd.Col span={24}>LGA: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.lga}</antd.Col>
              </antd.Row>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      ) : (
        <>
          <AffectedEnroleeDetail
            title={"Current Data"}
            data={omitKeys(targetData, keysToOmit)}
            changedKeys={[]}
          />
          <antd.Divider type="vertical" />
          <AffectedEnroleeDetail
            title={"Pre-action Data"}
            data={omitKeys(oldTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
          <antd.Divider type="vertical" />
          <AffectedEnroleeDetail
            title={"Post-action Data"}
            data={omitKeys(newTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
        </>
      )}
    </>
  );
};

const AffectedEnroleeDetail = ({ data, title, changedKeys }) => {
  const theTruthyManipulator = (obj) => {
    if (obj === undefined || obj === null) return {};

    const truthyValues = [
      "status",
      "flagged",
      "update_required",
      "updated",
      "date",
    ];

    const keys = Object.keys(obj);
    let objCopy = {};

    for (let k of keys) {
      if (truthyValues.includes(k)) {
        if (k === "date") {
          objCopy["registrastion_date"] = obj[k];
          continue;
        }
      }

      objCopy[k] = obj[k];
    }

    return objCopy;
  };

  return (
    <>
      <div style={{ width: "32%" }}>
        <antd.Divider
          type="horizontal"
          orientation="left"
          orientationMargin={0}
        >
          {title}
        </antd.Divider>
        <antd.Row
          align={"middle"}
          style={{ boxShadow: "2px 3px 5px rgba(0, 0, 0, .2)", padding: 10 }}
        >
          {Object.entries(theTruthyManipulator(data)).map((v) => (
            <antd.Col
              span={24}
              style={{
                color: changedKeys.includes(v[0]) ? "white" : "",
                backgroundColor: changedKeys.includes(v[0]) ? "red" : "",
              }}
            >
              <antd.Row>
                <antd.Col span={12}>
                  {underscoreToCapitalizedWords(v[0]).toUpperCase()}:{" "}
                </antd.Col>
                <antd.Col span={12}>
                  {v[0] === "status" && 
                    <antd.Tag color={Number(v[1]) === 1 ? "success" : "error"}>
                      {Number(v[1]) === 1 ? "Active" : "Disable"}
                    </antd.Tag>
                  }
                  {v[0] === "flagged" && 
                    <antd.Tag color={Number(v[1]) === 1 ? "error" : "success"}>
                      {Number(v[1]) === 1 ? "Flagged" : "Unflagged"}
                    </antd.Tag>
                  }
                  {v[0] === "update_required" && (
                    <antd.Tag color={Number(v[1]) === 1 ? "error" : "processing"}>
                      {Number(v[1]) === 1 ? "Yes" : "No"}
                    </antd.Tag>
                  )}
                  {v[0] === "updated" && (
                    <antd.Tag color={Number(v[1]) === 1 ? "success" : "error"}>
                      {Number(v[1]) === 1 ? "Updated" : "No Updated"}
                    </antd.Tag>
                  )}
                  {!(
                    v[0] === "status" ||
                    v[0] === "flagged" ||
                    v[0] === "update_required" ||
                    v[0] === "updated"
                  ) && v[1]}
                </antd.Col>
              </antd.Row>
            </antd.Col>
          ))}
        </antd.Row>
      </div>
    </>
  );
};

const AffectedClaimItem = ({ data, type }) => {
  const oldTargetData = data["logs_target_old_data"]
    ? JSON.parse(data["logs_target_old_data"])
    : {};
  const newTargetData = data["logs_target_new_data"]
    ? JSON.parse(data["logs_target_new_data"])
    : {};

  const targetData = data?.targetData ? data.targetData : {};

  const keysToOmit = ["doc", "_type", "id_"];

  const changedKeys = findDifferentKeysTwoObj(
    oldTargetData,
    newTargetData,
    keysToOmit
  );

  return (
    <>
      <antd.Divider
        orientation={type ? "center" : "left"}
        orientationMargin={0}
      >
        Affected Data - Claimed Item - {type ? "Detailed" : "Brief"}
      </antd.Divider>
      {!type ? (
        <antd.Col span={24}>
          <antd.Row align={"middle"}>
            <antd.Col span={5}>
              <antd.Row>
                <antd.Col span={24}>Checkin ID: </antd.Col>
                <antd.Col span={24}>{data.targetData?.checkin_id}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={6}>
              <antd.Row>
                <antd.Col span={24}>Auth Code: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.auth_code}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={2}>
              <antd.Row>
                <antd.Col span={24}>Item ID: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.item_id}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={2}>
              <antd.Row>
                <antd.Col span={24}>Quantity: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.quantity}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Filed Cost: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.cost}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Approved Cost: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.approved_cost}</antd.Col>
              </antd.Row>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      ) : (
        <>
          <AffectedClaimItemDetail
            title={"Current Data"}
            data={omitKeys(targetData, keysToOmit)}
            changedKeys={[]}
          />
          <antd.Divider type="vertical" />
          <AffectedClaimItemDetail
            title={"Pre-action Data"}
            data={omitKeys(oldTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
          <antd.Divider type="vertical" />
          <AffectedClaimItemDetail
            title={"Post-action Data"}
            data={omitKeys(newTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
        </>
      )}
    </>
  );
};

const AffectedClaimItemDetail = ({ data, title, changedKeys }) => {
  const theTruthyManipulator = (obj) => {
    if (obj === undefined || obj === null) return {};

    const truthyValues = [
      "claim_item_status",
      "claim_flag",
      "approved_cost",
      "cost",
      "date",
    ];

    const keys = Object.keys(obj);
    let objCopy = {};

    for (let k of keys) {
      if (truthyValues.includes(k)) {
        if (k === "date") {
          objCopy["date_submitted"] = obj[k];
          continue;
        }
      }

      objCopy[k] = obj[k];
    }
    console.log(objCopy, obj);
    return objCopy;
  };

  return (
    <>
      <div style={{ width: "32%" }}>
        <antd.Divider
          type="horizontal"
          orientation="left"
          orientationMargin={0}
        >
          {title}
        </antd.Divider>
        <antd.Row
          align={"middle"}
          style={{ boxShadow: "2px 3px 5px rgba(0, 0, 0, .2)", padding: 10 }}
        >
          {Object.entries(theTruthyManipulator(data)).map((v) => (
            <antd.Col
              span={24}
              style={{
                color: changedKeys.includes(v[0]) ? "white" : "",
                backgroundColor: changedKeys.includes(v[0]) ? "red" : "",
                borderBottom: "1px soild black",
              }}
            >
              <antd.Row>
                <antd.Col span={9}>
                  {underscoreToCapitalizedWords(v[0]).toUpperCase()}:{" "}
                </antd.Col>
                <antd.Col span={15}>
                  {(v[0] === "approved_cost" || v[0] === "cost") && (
                    <CurrencyFormat
                      value={v[1]}
                      thousandSeparator
                      prefix="₦"
                      displayType="text"
                    />
                  )}
                  {(v[0] === "claim_item_status" || v[0] === "claim_flag") &&
                    claim_items_statuses(v[1])}
                  {!(
                    v[0] === "approved_cost" ||
                    v[0] === "cost" ||
                    v[0] === "claim_item_status" ||
                    v[0] === "claim_flag"
                  ) && v[1]}
                </antd.Col>
              </antd.Row>
            </antd.Col>
          ))}
        </antd.Row>
      </div>
    </>
  );
};

const AffectedStaff = ({ data, type }) => {
  const oldTargetData = data["logs_target_old_data"]
    ? JSON.parse(data["logs_target_old_data"])
    : {};
  const newTargetData = data["logs_target_new_data"]
    ? JSON.parse(data["logs_target_new_data"])
    : {};

  const targetData = data?.targetData ? data.targetData : {};

  const keysToOmit = ["password", "id"];

  const changedKeys = findDifferentKeysTwoObj(
    oldTargetData,
    newTargetData,
    keysToOmit
  );

  return (
    <>
      <antd.Divider
        orientation={type ? "center" : "left"}
        orientationMargin={0}
      >
        Affected Data - Staff - {type ? "Detailed" : "Brief"}
      </antd.Divider>
      {!type ? (
        <antd.Col span={24}>
          <antd.Row align={"middle"}>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Staff Id: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.staffId}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={5}>
              <antd.Row>
                <antd.Col span={24}>Full Name: </antd.Col>
                <antd.Col span={24}>
                  {data.targetData?.firstName} {data.targetData?.middleName}{" "}
                  {data.targetData?.firstName &&
                    data.targetData?.Surname &&
                    ","}{" "}
                  {data.targetData?.Surname?.toUpperCase()}
                </antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={6}>
              <antd.Row>
                <antd.Col span={24}>Staff Role: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.staffRoleName}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Phone: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.phone}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>State / City: </antd.Col>
                <antd.Col span={24}>
                  {data?.targetData?.state} / {data.targetData?.city}
                </antd.Col>
              </antd.Row>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      ) : (
        <>
          <AffectedStaffDetail
            title={"Current Data"}
            data={omitKeys(targetData, keysToOmit)}
            changedKeys={[]}
          />
          <antd.Divider type="vertical" />
          <AffectedStaffDetail
            title={"Pre-action Data"}
            data={omitKeys(oldTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
          <antd.Divider type="vertical" />
          <AffectedStaffDetail
            title={"Post-action Data"}
            data={omitKeys(newTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
        </>
      )}
    </>
  );
};

const AffectedStaffDetail = ({ data, title, changedKeys }) => {
  const theTruthyManipulator = (obj) => {
    if (obj === undefined || obj === null) return {};

    const truthyValues = ["status"];

    const keys = Object.keys(obj);
    let objCopy = {};

    for (let k of keys) {
      if (truthyValues.includes(k)) {
        if (k === "status")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "success" : "error"}>
              {parseInt(obj[k]) ? "Active" : "Disable"}
            </antd.Tag>
          );
        if (k === "flagged")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "error" : "success"}>
              {parseInt(obj[k]) ? "Flagged" : "Unflagged"}
            </antd.Tag>
          );
        if (k === "update_required")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "error" : "processing"}>
              {parseInt(obj[k]) ? "Yes" : "No"}
            </antd.Tag>
          );
        if (k === "updated")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "success" : "error"}>
              {parseInt(obj[k]) ? "Updated" : "No Updated"}
            </antd.Tag>
          );
        if (k === "date") {
          objCopy["date_submitted"] = obj[k];
          continue;
        }
      }

      objCopy[k] = obj[k];
    }

    return objCopy;
  };

  return (
    <>
      <div style={{ width: "32%" }}>
        <antd.Divider
          type="horizontal"
          orientation="left"
          orientationMargin={0}
        >
          {title}
        </antd.Divider>
        <antd.Row
          align={"middle"}
          style={{ boxShadow: "2px 3px 5px rgba(0, 0, 0, .2)", padding: 10 }}
        >
          {Object.entries(theTruthyManipulator(data)).map((v) => (
            <antd.Col
              span={24}
              style={{
                color: changedKeys.includes(v[0]) ? "white" : "",
                backgroundColor: changedKeys.includes(v[0]) ? "red" : "",
                borderBottom: "1px soild black",
              }}
            >
              <antd.Row>
                <antd.Col span={9}>
                  {underscoreToCapitalizedWords(v[0]).toUpperCase()}:{" "}
                </antd.Col>
                <antd.Col span={15}>{v[1]}</antd.Col>
              </antd.Row>
            </antd.Col>
          ))}
        </antd.Row>
      </div>
    </>
  );
};

const AffectedClaim = ({ data, type }) => {
  const oldTargetData = data["logs_target_old_data"]
    ? JSON.parse(data["logs_target_old_data"])
    : {};
  const newTargetData = data["logs_target_new_data"]
    ? JSON.parse(data["logs_target_new_data"])
    : {};

  const targetData = data?.targetData ? data.targetData : {};

  const keysToOmit = ["id"];

  const changedKeys = findDifferentKeysTwoObj(
    oldTargetData,
    newTargetData,
    keysToOmit
  );

  return (
    <>
      <antd.Divider
        orientation={type ? "center" : "left"}
        orientationMargin={0}
      >
        Affected Data - Claim - {type ? "Detailed" : "Brief"}
      </antd.Divider>
      {!type ? (
        <antd.Col span={24}>
          <antd.Row align={"middle"}>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Claim ID: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.claims_id}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Row>
                <antd.Col span={24}>Checkin ID: </antd.Col>
                <antd.Col span={24}>{data.targetData?.checkin_id}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Row>
                <antd.Col span={24}>Payment Batch: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.payment_batch}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={5}>
              <antd.Row>
                <antd.Col span={24}>Date Submitted: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.claims_date}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={4}>
              <antd.Row>
                <antd.Col span={24}>Date Paid: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.date_paid}</antd.Col>
              </antd.Row>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      ) : (
        <>
          <AffectedClaimDetail
            title={"Current Data"}
            data={omitKeys(targetData, keysToOmit)}
            changedKeys={[]}
          />
          <antd.Divider type="vertical" />
          <AffectedClaimDetail
            title={"Pre-action Data"}
            data={omitKeys(oldTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
          <antd.Divider type="vertical" />
          <AffectedClaimDetail
            title={"Post-action Data"}
            data={omitKeys(newTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
        </>
      )}
    </>
  );
};

const AffectedClaimDetail = ({ data, title, changedKeys }) => {
  const theTruthyManipulator = (obj) => {
    if (obj === undefined || obj === null) return {};

    const truthyValues = ["claims_status"];

    const keys = Object.keys(obj);
    let objCopy = {};

    for (let k of keys) {
      if (truthyValues.includes(k)) {
        if (k === "claims_status")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "success" : "error"}>
              {parseInt(obj[k]) ? "Active" : "Disable"}
            </antd.Tag>
          );
        if (k === "flagged")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "error" : "success"}>
              {parseInt(obj[k]) ? "Flagged" : "Unflagged"}
            </antd.Tag>
          );
        if (k === "update_required")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "error" : "processing"}>
              {parseInt(obj[k]) ? "Yes" : "No"}
            </antd.Tag>
          );
        if (k === "updated")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "success" : "error"}>
              {parseInt(obj[k]) ? "Updated" : "No Updated"}
            </antd.Tag>
          );
        if (k === "date") {
          objCopy["date_submitted"] = obj[k];
          continue;
        }
      }

      objCopy[k] = obj[k];
    }

    return objCopy;
  };

  return (
    <>
      <div style={{ width: "32%" }}>
        <antd.Divider
          type="horizontal"
          orientation="left"
          orientationMargin={0}
        >
          {title}
        </antd.Divider>
        <antd.Row
          align={"middle"}
          style={{ boxShadow: "2px 3px 5px rgba(0, 0, 0, .2)", padding: 10 }}
        >
          {Object.entries(theTruthyManipulator(data)).map((v) => (
            <antd.Col
              span={24}
              style={{
                color: changedKeys.includes(v[0]) ? "white" : "",
                backgroundColor: changedKeys.includes(v[0]) ? "red" : "",
                borderBottom: "1px soild black",
              }}
            >
              <antd.Row>
                <antd.Col span={9}>
                  {underscoreToCapitalizedWords(v[0]).toUpperCase()}:{" "}
                </antd.Col>
                <antd.Col span={15}>{v[1]}</antd.Col>
              </antd.Row>
            </antd.Col>
          ))}
        </antd.Row>
      </div>
    </>
  );
};

const AffectedCheckin = ({ data, type }) => {
  const oldTargetData = data["logs_target_old_data"]
    ? JSON.parse(data["logs_target_old_data"])
    : {};
  const newTargetData = data["logs_target_new_data"]
    ? JSON.parse(data["logs_target_new_data"])
    : {};

  const targetData = data?.targetData ? data.targetData : {};

  const keysToOmit = ["id"];

  const changedKeys = findDifferentKeysTwoObj(
    oldTargetData,
    newTargetData,
    keysToOmit
  );
  return (
    <>
      <antd.Divider
        orientation={type ? "center" : "left"}
        orientationMargin={0}
      >
        Affected Data - Checkin - {type ? "Detailed" : "Brief"}
      </antd.Divider>
      {!type ? (
        <antd.Col span={24}>
          <antd.Row align={"middle"}>
            <antd.Col span={5}>
              <antd.Row>
                <antd.Col span={24}>Checkin ID: </antd.Col>
                <antd.Col span={24}>{data.targetData?.code}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={3}>
              <antd.Row>
                <antd.Col span={24}>Insurance Number: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.insurance_no}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={6}>
              <antd.Row>
                <antd.Col span={24}>Checkin Type: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.checkinType}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={2}>
              <antd.Row>
                <antd.Col span={24}>Medical Compalaint: </antd.Col>
                <antd.Col span={24}>
                  {data?.targetData?.medical_complaint_hm}
                </antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={2}>
              <antd.Row>
                <antd.Col span={24}>Check-in Date: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.checkin_date}</antd.Col>
              </antd.Row>
            </antd.Col>
            <antd.Col span={2}>
              <antd.Row>
                <antd.Col span={24}>Check-out Date: </antd.Col>
                <antd.Col span={24}>{data?.targetData?.checkout_date}</antd.Col>
              </antd.Row>
            </antd.Col>
          </antd.Row>
        </antd.Col>
      ) : (
        <>
          <AffectedCheckinDetail
            title={"Current Data"}
            data={omitKeys(targetData, keysToOmit)}
            changedKeys={[]}
          />
          <antd.Divider type="vertical" />
          <AffectedCheckinDetail
            title={"Pre-action Data"}
            data={omitKeys(oldTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
          <antd.Divider type="vertical" />
          <AffectedCheckinDetail
            title={"Post-action Data"}
            data={omitKeys(newTargetData, keysToOmit)}
            changedKeys={changedKeys}
          />
        </>
      )}
    </>
  );
};

const AffectedCheckinDetail = ({ data, title, changedKeys }) => {
  const theTruthyManipulator = (obj) => {
    if (obj === undefined || obj === null) return {};

    const truthyValues = ["checkin_status"];

    const keys = Object.keys(obj);
    let objCopy = {};

    for (let k of keys) {
      if (truthyValues.includes(k)) {
        if (k === "claims_status")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "success" : "error"}>
              {parseInt(obj[k]) ? "Active" : "Disable"}
            </antd.Tag>
          );
        if (k === "flagged")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "error" : "success"}>
              {parseInt(obj[k]) ? "Flagged" : "Unflagged"}
            </antd.Tag>
          );
        if (k === "update_required")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "error" : "processing"}>
              {parseInt(obj[k]) ? "Yes" : "No"}
            </antd.Tag>
          );
        if (k === "updated")
          obj[k] = (
            <antd.Tag color={parseInt(obj[k]) ? "success" : "error"}>
              {parseInt(obj[k]) ? "Updated" : "No Updated"}
            </antd.Tag>
          );
        if (k === "date") {
          objCopy["date_submitted"] = obj[k];
          continue;
        }
      }

      objCopy[k] = obj[k];
    }

    return objCopy;
  };

  return (
    <>
      <div style={{ width: "32%" }}>
        <antd.Divider
          type="horizontal"
          orientation="left"
          orientationMargin={0}
        >
          {title}
        </antd.Divider>
        <antd.Row
          align={"middle"}
          style={{ boxShadow: "2px 3px 5px rgba(0, 0, 0, .2)", padding: 10 }}
        >
          {Object.entries(theTruthyManipulator(data)).map((v) => (
            <antd.Col
              span={24}
              style={{
                color: changedKeys.includes(v[0]) ? "white" : "",
                backgroundColor: changedKeys.includes(v[0]) ? "red" : "",
                borderBottom: "1px soild black",
              }}
            >
              <antd.Row>
                <antd.Col span={9}>
                  {underscoreToCapitalizedWords(v[0]).toUpperCase()}:{" "}
                </antd.Col>
                <antd.Col span={15}>{v[1]}</antd.Col>
              </antd.Row>
            </antd.Col>
          ))}
        </antd.Row>
      </div>
    </>
  );
};

const Logging = () => {
  return (
    <>
      <antd.Row gutter={16}>
        <antd.Col span={24}>
          <Log />
        </antd.Col>
      </antd.Row>
    </>
  );
};

export default Logging;
