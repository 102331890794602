import React, { useEffect } from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Navbar from "./nav";
import config from "../assets/utils/config";
import { useAuth } from "../assets/utils/useAuth";
import CopyRight from "./compoinents/copywrite";


const Layout = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(!user) {
      navigate('/login');
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  window.onunload = () => {
    localStorage.setItem(config.key.q_count, 0)
    localStorage.setItem(config.key.q_reading, 0)
    localStorage.setItem(config.key.activeSeg, 0)
    localStorage.setItem(config.key.activePrifileType, 0)
  }

  
  return (
    <>
      {location.pathname !== '/login' && (<Navbar />)}
      <audio preload="auto" src="" id="audio"></audio>
      
      <div className='container justify-content-betwween'>
        <Outlet />
      </div>
      {location.pathname !== '/login' && ( <CopyRight /> )}
    </>
  );
};

export default Layout;