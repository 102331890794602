import React, { useState } from 'react';
import { Modal, Input, Button, Form, Alert, Row, Col } from 'antd';
import { validateEmailDomain } from '../../assets/utils/Helpers';

const EmailVerificationModal = ({ title = '', domain, onSuccess, onCancel, payload }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        setError(null);

        const validationResult = validateEmailDomain(email+'@'+domain);

        if (!validationResult.isValid) {
            setError(validationResult.error);
            setLoading(false);
            return;
        }

        if (onSuccess) {
            await onSuccess(email);
        }

        setLoading(false);
    };

    if (!payload) {
        return;
    }

    return (
        <Modal
            title={title}
            open={true}
            onOk={handleSubmit}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel} disabled={loading}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} disabled={loading} onClick={handleSubmit}>
                    Submit
                </Button>
            ]}
        >
            {/* <Form > */}
                <Row gutter={[10,20]} justify={'space-between'} align="middle" style={{ margin: '50px 10px' }}>
                    <Col span={16}>
                        {/* <Form.Item> */}
                            <Input
                                placeholder="Enter Recipient email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onPressEnter={handleSubmit}
                            />
                        {/* </Form.Item> */}
                    </Col>
                    <Col span={1}>@</Col>
                    <Col span={6}>
                        <Input
                            placeholder="Enter Recipient email"
                            value={domain}
                            disabled
                        />
                    </Col>
                    <Col span={24}>
                        {error && <Alert message={error} type="error" showIcon />}
                    </Col>
                </Row>
            {/* </Form> */}
        </Modal>
    );
};

export default EmailVerificationModal;
