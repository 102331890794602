import React, { useEffect, useState } from 'react';
import './Loader.css'; // Import CSS for styling

const Loader = ({ size = 50, color = '#3498db' }) => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotation(prevRotation => prevRotation + 1);
    }, 10);

    return () => clearInterval(interval);
  }, []);

  const loaderStyle = {
    width: size,
    height: size,
    borderTopColor: color,
    transform: `rotate(${rotation}deg)`,
  };

  return (
    <div className="loader-container">
      <div className="loader" style={loaderStyle}></div>
    </div>
  );
};

export default Loader;
