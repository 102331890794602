import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import PreAuthReducer from '../stores/preauth';
import PreAuthReducerManual from '../stores/preauth/indexFiling';
import UserReducer from '../stores/user';
import ClientReducer from '../stores/verify';
import ClaimsReducer from '../stores/Claims';
import  Queries  from '../stores/queries';

export const store = configureStore({
  reducer: {
    preAuth: PreAuthReducer,
    preAuthManual: PreAuthReducerManual,
    userAuth: UserReducer,
    ClientStore: ClientReducer,
    claims: ClaimsReducer,
    QueriesStore: Queries
  },
});
