import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {}
};

export const AppUser = createSlice({
  name: 'appUser',
  initialState,
  reducers: {
    add_user: (state, action) => {
      state.user = action.payload;
    },
    remove_user: (state) => {
      state.user = {};
    }
  }
});

export const { add_user, remove_user } = AppUser.actions;

export const selectUser = (state) => state.AppUser.user;


export default AppUser.reducer;
