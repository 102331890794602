import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';

const ACheckbox = ({ label, checked, onChange, ...props }) => {
    const [status, setStatus] = useState(checked);
    useEffect(() => {
        onChange && onChange(status)
    }, [status, onChange])

    return (
        <Checkbox
            checked={status}
            onChange={(e) => {setStatus(e.target.checked)}}
            {...props}
        >
            {label}
        </Checkbox>
    );
};

export default ACheckbox;
