import React from 'react';
import { Table } from 'antd';

const SearchableTable = ({columns, dataSource, renderRow, loading, expandable, ...props}) => {
    return (
        <Table
            dataSource={dataSource}
            columns={columns.map(col => {
                if (!col.filterDropdown) {
                    return col;
                }
                return {
                    ...col,
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => searchInput.select(), 100);
                        }
                    },
                };
            })}
            pagination={dataSource?.length > 10 && { pageSize:  10 }}
            rowKey="key"
            rowClassName="editable-row"
            renderItem={renderRow}
            expandable={expandable && {
                expandedRowRender: record => renderRow(record),
            }}
            loading={loading}
            {...props}
        />
    );
};

export default SearchableTable;
