import { Button, Input, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import LogoHook from "../logo"
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../assets/utils/useAuth';
import lib from '../../assets/utils/lib';
import './login.css';

import { useDispatch} from 'react-redux'

import {
    add_user,
    selectUser
} from '../../stores/user';
import { SyncOutlined } from '@ant-design/icons';
import CopyRight from '../compoinents/copywrite';

const LoginPageHook = props => {
  const dispatcher = useDispatch(selectUser);

  const [data, setData] = useState({});
  const { set, user} = useAuth();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);

  const key = 'login';

  useEffect(() => {
    if (user) 
      switch(Number(user.role.staffRoleId)){
        case 1:
          navigate('/dash');
          break;
          // navigate('/preauth');
          // break;
        case 2:
        case 3:
        case 4:
        case 5:
          navigate('/dashboard');
          // navigate('/claims');
          break;
        default:
          navigate('/verify');
      }
  }, [navigate, user])

  const logUserIn = async () => {
    setLoading(true)

    if(Object.keys(data).length < 2) {
      notification.error({
        message: 'Missing Field',
        description: 'make sure all the fields were appropriately filled',
        duration: 3,
        key, placement: 'topLeft'
      })
      setLoading(false)
      return;
    }

    notification.info({ 
      icon: <SyncOutlined spin size={'medium'}/>,
      message: <>
        <span><strong>Logging in...</strong></span><br></br>
        <span>Please Wait</span>
      </>, 
      key, placement: 'topLeft' 
    });
    let reqData = await lib.login(data)
    if(reqData?.status === 'error'){
    } else if (parseInt(reqData?.data?.error)) {
      notification.error({ message: reqData.data.message, duration: 2, key, placement: 'topLeft'});
    } else {
      notification.success({ message: reqData.data.message, duration: 3, key, placement: 'topLeft'});
      dispatcher(add_user({user: reqData?.data.data}));
      set(reqData?.data.data)
    }
    setLoading(false)
  }

  return(
    <>
      <div className="loginPage App-header">
        <form className='form'>
          <LogoHook style={{margin: '30px'}}/>
          <div className="input-group mb-4">
            <label htmlFor="form1Example1" style={{color: 'black', fontWeight: 'bold', fontSize: '18px'}}>Username</label>
            <Input type="email" id="form1Example1" onChange={e => setData(d => ({...d, staffId: e.target.value}))} onPressEnter={logUserIn}/>
          </div>
  
          <div className="input-group mb-4">
            <label htmlFor="form1Example2"  style={{color: 'black', fontWeight: 'bold', fontSize: '18px'}}>Password</label>
            <Input.Password  id="form1Example2" onChange={e => setData(d => ({...d, password: e.target.value}))} onPressEnter={logUserIn}/>
          </div>

          <Button type="dashed" className="btn btn-primary btn-block" style={{width: '100%', fontWeight: 'bold'}} onClick={logUserIn} loading={loading} disabled={loading}>Login</Button>
        </form>

        <CopyRight />
      </div>
    </>
    
  )
}

export default LoginPageHook;