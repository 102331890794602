import html2canvas from "html2canvas";
import React, { useRef, useState } from "react";
import logom from '../../assets/images/icon/amanhmo.svg';
import { uri_img } from "../../assets/utils/http-request";
import { Button, Col, Drawer, Row, Space } from "antd";
import QRCode from "react-qr-code";
import { IdcardOutlined } from "@ant-design/icons";

export const CareCard = (props) => {
  const item = props.data;
  const imgs = useRef(logom);
  const [downloadIDCARD, setDownloadIDCARD] = useState(false);

  const idCard = () => {
      setDownloadIDCARD(true)
  }

  const downloadCard = () => {
      html2canvas(document.querySelector(".id-container"), { logging: true, letterRendering: 1, allowTaint: false, useCORS: true }).then(canvas => {
        var img   = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.download = `${item.insurance_no}.png`
        link.href = img;

        const triggerDownload = () => {
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };

        triggerDownload();
      });
  }

  let imageStyle = {
      width: "150px",
      height: "150px",
      margin: '0 auto',
      marginTop: 10,
      borderRadius: '50%',
  }

  imgs.current = item.pic_name ? `${uri_img}${item.pic_name}` : logom

  return (
      <>
        <Button onClick={idCard} icon={<IdcardOutlined />} >View ID Card</Button>
        <Drawer title="ID Card Viewer" placement="right" onClose={() => setDownloadIDCARD(false)} open={downloadIDCARD} extra={
          <Space>
            <Button onClick={downloadCard} type="primary" disabled={item.pic_name? false : true}>
              Download
            </Button>
          </Space>
        }>
          <div className="id-container">
              <div className="id-container-head">
                  <img src={logom} alt="Aman Logo" />
              </div>
              <div className="id-container-qr">
                  <QRCode value={'https://id.amanmedicare.com/verify/?user=' + item?.hash}  level='M' size={100}/>
              </div>
              <div className="id-container-body">
                  <div className="id-container-body-image">
                      <img style={imageStyle} src={imgs.current} alt={item?.surname[0]?.toUpperCase() + item?.first_name[0]?.toUpperCase()}/>
                  </div>
                  <div className="id-container-body-name-div">
                      <p className="id-container-body-name-div-name">{item?.first_name || ''} {item?.middle_name || ''} {item?.surname || ''}</p>
                      <Row justify='space-between' className="id-container-body-name-div-name-policy">
                          <Col span={24}>
                              <Row>
                                  <Col span={12}><span className="id-container-body-name-div-id">Insurance No.:</span></Col>
                                  <Col span={12}><span style={{color: "#34495e"}}>{item?.insurance_no || ''}</span></Col>
                              </Row>
                          </Col>
                          <Col span={24}>
                              <Row>
                                  <Col span={12}><span className="id-container-body-name-div-id">Expires:</span></Col>
                                  <Col span={12}><span style={{color: "#34495e"}}>{(new Date(props?.policy?.expiry_date)).toDateString()}</span></Col>
                              </Row>
                          </Col>
                      </Row>
                  </div>
              </div>
          </div>
        </Drawer>
      </>
    
  )
}